const common = {
  Merchant: {
    Login: {
      label: "Verkoper inloggen",
      "email-label": "E-mailadres",
      "password-label": "Wachtwoord",
      button: {
        "Login-button": "Log in",
      },
    },
  },
  TransactionsResult: {
    columnDefs: {
      Action: "Actie",
      TransactionRefID: "Transactiereferentie-ID",
      Amount: "Hoeveelheid",
      status: "Toestand/Type",
      Date: "Datum",
      StartDate: "StartDate",
      EndDate: "EndDate",
      DASMID: "DAS MID",
      Scheme: "Schema",
      Merchant: "Handelaar",
      Acquirer: "verkrijger",
      Type: "Type",
      TransactionType: "Transaction Type",
      TimeZone: "Transaction TimeZone",
    },
    BreadCrumb: {
      Home: "Huis",
      "Search-Transaction-Result": "Zoek-transactie-resultaat",
    },
    DownloadCSV: "Downloaden CSV",
  },
  TransactionDetailDrawerBody: {
    TransactionHistory: "Transactie Geschiedenis",
    AuditLog: "Audit Log",
  },
  SideMenu: {
    MenuItems: {
      Transactions: "Transacties",
      Merchants: "handelaars",
      Entity: "Configuratie van entiteitsrisicoregel",
      Acquirers: "verkrijgers",
    },
  },
  FilterPopup: {
    button: {
      Filter: "Filter",
    },
  },
  MerchantList: {
    Merchants_View_ColumnDefs: {
      Action: "Actie",
      MerchantAccount: "Handelaarsaccount",
      Products: "Producten",
      Country: "Land",
      CreatedDate: "Aanmaakdatum",
      Status: "Toestand",
      StartDate: "",
      EndDate: "",
      DASMID: "DAS MID",
    },
    Product_View_ColumnDefs: {
      Action: "Actie",
      MerchantName: "Naam verkoper",
      ProductName: "productnaam",
      MCC: "MCC",
      DASMID: "DAS MID",
      Tooltip: {
        View_Details: "Details bekijken",
        AddRiskRule: "Risicoregel toevoegen",
        AddPayByLink: "Betaal via link toevoegen",
      },
    },
    tabs: {
      Merchant: "Verkoper bekijken",
      Product: "Productweergave",
    },
    breadcrumbs: {
      Home: "Huis",
      Merchants: "handelaars",
    },
    MerchantTable: {
      View_Details: "Details bekijken",
    },
    Action: "Actie",
    TerminalId: "Terminal-ID",
    LocationId: "Locatie-ID",
  },
  AcquirerList: {
    AcquirerList_ColumnDefs: {
      Action: "Actie",
      AcquirerName: "Naam verwerver",
      AcquirerCode: "Verkrijgercode",
      SettlementOffset: "Verrekening compensatie",
      DateInserted: "Datum ingevoegd",
      Status: "Toestand",
    },
    breadcrumbs: {
      Home: "Huis",
      Acquirer: "verwerver",
    },
    AddAcquirer: "Verwerver toevoegen",
  },
  TransactionDetail: {
    TransactionDetailHeading: "TRANSACTIE DETAILS",
    breadcrumb: {
      Home: "Huis",
      SearchTransactionDetails: "Transactiedetails zoeken",
    },
    TransactionInfo: {
      label: "Transactie-informatie",
      fields: {
        TransactionRefID: "Transactie Ref ID",
        DASMID: "DAS MID",
        Merchant: "handelaar",
        TransactionType: "Transactie Type",
        Date: "Datum",
        Amount: "Hoeveelheid",
        CurrencyCode: "Valuta code",
        Response: "Antwoord",
        Status: "Toestand",
        AuthCode: "autorisatie code",
        CVVResponse: "CVV Antwoord",
        GatewayError: "Gateway Fout",
      },
    },
    CustomerDetails: {
      label: "Klant details",
      field: {
        CardNumber: "Kaartnummer",
        Scheme: "Schema",
        ExpiryDate: "Vervaldatum (MM/ JJJJ)",
        CardHolder: "Kaarthouder",
        EmailAddress: "E-mailadres",
        Phone: "Telefoon",
        BillingCountry: "Factureringsland",
        BillingPostcode: "Postcode facturering",
        BillingAddress: "Facturatie adres",
        ShippingCountry: "Land van verzending",
        ShippingPostcode: "Postcode verzending",
        ShippingAddress: "Verzendingsadres",
      },
    },
    AdditionalInformation: {
      label: "Extra informatie",
      fields: {
        IssuingBank: "Uitgevende bank",
        IssuingCountry: "Land van uitgifte",
        BIN: "BIN",
        TransactionTimezone: "Transactie tijdzone",
        MerchantCategoryCode: "Merchant CategorieCode",
        MerchantIP: "IP-adres van verkoper",
        CustomerIP: "IP van klant",
        Acquirer: "verkrijger",
        AcquirerMID: "verkrijger MID",
        AcquirerReferenceNumber: "Referentienummer verkrijger",
        ACQError: "Acquirer-referentiefout",
      },
    },
    TransactionHistory: {
      label: "Transactie Geschiedenis",
    },
    TransactionListButtonPopper: {
      button: {
        Details: "Details",
      },
    },
    actionButton: {
      REFUND: "TERUGBETALING",
      CAPTURE: "Vastleggen",
      Void_Authorisation: "Autorisatie ongeldig maken",
    },
    refundButton: "Terugbetaling",
  },
  ActionButtons: {
    TransactionEvent: "Transactiegebeurtenis",
  },
  RefundTransaction: {
    title: "EEN TERUGBETALING MAKEN",
    Amount: "Oorspronkelijke hoeveelheid",
    RemainingAmount: "Overig",
    RefundAmount: "restitutiebedrag",
    Reference: "Referentie(Optioneel)",
    ConfirmRefundCheckbox:
      "Ja, ik wil deze terugbetaling graag doen en ik begrijp dat deze actie niet onomkeerbaar is. Ga verder om dit restitutieproces te starten.",
    helperText: {
      RefundAmount_helperTxt: "Waarde van het getal moet groter zijn dan 0",
    },
    message: {
      ConfirmRefundCheckbox:
        "Bevestig a.u.b. of u door wilt gaan met dit verzoek.",
      RefundAmount: "De waarde van het getal mag maximaal twee decimalen zijn",
    },
  },
  Merchant_Detail: {
    MerchantDetail: {
      label: "Verkoperdetails",
      fields: {
        LegalName: "Legale naam",
        RegistrationNumber: "Registratie nummer",
        SubsidiaryID: "PO Entiteit-ID",
        Company_Address_Information: "Bedrijfsadresgegevens",
        City: "Stad",
        PostalCode: "Postcode",
        Country: "Land",
      },
    },
    API_keys: {
      label: "API-sleutels",
      fields: {
        Live_API_key: "Live API-sleutel",
        Test_API_key: "API-sleutel testen",
      },
    },
    Product_Information: {
      label: "Productinformatie",
      columnDefs: {
        Action: "Actie",
        Name: "Naam",
        Type: "Type",
        MCC: "MCC",
        DASMID: "DAS MID",
      },
      View_Details: "Details bekijken",
      Add_Risk_Rule: "Risicoregel toevoegen",
    },
    ContactDetail: {
      label: "Contact details",
      fields: {
        FirstName: "Voornaam",
        LastName: "Achternaam",
        TelephoneNumber: "Telefoon nummer",
        EmailAddress: "E-mailadres",
      },
    },
    breadcrumbs: {
      Home: "Huis",
      Merchants: "handelaars",
      MerchantsDetails: "Verkopersdetails",
    },
  },
  ProductModal: {
    MerchantName: "Naam verkoper",
    ShopProcessingURL: "winkel verwerkings-url",
    MerchantCategoryCode: "handelaar categoriecode",
    Currency: "Munteenheid",
    AcquirerMID: "verkrijger MID",
    DASMID: "DAS MID",
    BillingDescriptor: "factureringsdescriptor",
    SchemeTypes: "schema typen",
    ConnectionMethods: {
      label: "connection methods",
      value: {
        HostedPaymentPage: "Gehoste betalingspagina",
        ServerToServerApi: "Server naar Server (API)",
      },
    },
    TransactionType: {
      label: "Transactie Type",
      value: {
        "3DSecure": "3D Veilig",
        Non3DSecure: "Niet 3D Veilig",
        "CVV2/CVC2": "CVV2/CVC2",
        "NonCVV2/CVC2": "Niet CVV2/CVC2",
        Recurring: "Terugkerend",
      },
    },
    button: {
      closeButton: "Dichtbij",
    },
  },
  Acquirer_Details: {
    AcquirerDetails: {
      label: "Gegevens overnemer",
      fields: {
        AcquirerName: "Naam verwerver",
        TimeZone: "Tijdzone",
        URL1: "Url",
        Country: "Land",
        ChargebackInvestigationDays: "Terugboekingsonderzoeksdagen",
        AcquirerIndentifier: "Identificatie van de overnemer",
        RetrievalInvestigationDays: "Ophalen Integratiedagen",
        SettlementOffset: "Verrekening compensatie",
        Token: "token",
        Sender: "afzender",
        Channel: "kanaal",
        Status: "Toestand",
      },
    },
    AcquirerMID: {
      label: "Verkrijger MID Details",
      fields: {
        MerchantCodes: "Verkopers Codes",
        PaymentCurrency: "Betalingsvaluta",
        SettlementCurrency: "Afwikkelingsvaluta",
        Description: "Beschrijving",
      },
    },
    breadcrumbs: {
      Home: "Huis",
      Acquirer: "Verkrijger",
      AcquirerDetails: "Verkrijger Details",
    },
  },
  Add_Acquirer: {
    AddAcquirer: {
      label: "Verwerver toevoegen",
      fields: {
        AddAcq: "Naam verwerver",
        TimeZone: "Tijdzone",
        URL1: "Url",
        Country: "Land",
        ChargebackInvestigationDays: "Terugboekingsonderzoeksdagen",
        AcquirerIndentifier: "verkrijger ID",
        RetrievalInvestigationDays: "Ophalen Integratiedagen",
        SettlementOffset: "Verrekening compensatie",
        Status: "Toestand",
        Token: "Token",
        Sender: "Afzender",
        Channel: "Kanaal",
      },
      breadcrumb: {
        Home: "Huis",
        Acquirer: "Verkrijger",
        AddAcquirer: "Verwerver toevoegen",
      },
      button: {
        Submit: "Indienen",
        Cancel: "Annuleren",
      },
    },
  },
  EditAcquirer: {
    label: "Acquirergegevens bewerken",
    breadcrumb: {
      Home: "Huis",
      Acquirer: "Verwerver",
      AcquirerDetails: "Verwerver Details",
      EditAcquirer: "Bewerk Verwerver",
    },
  },
  Filter: {
    AdvancedFilters: "Geavanceerde filters",
    Add_New_Filter: "Nieuw filter toevoegen",
    button: {
      Reset: "Resetten",
      Apply: "Van toepassing zijn",
    },
    field: "Veld",
    Value: "Waarde",
    AUTHORISATION: "autorisatie",
    PURCHASE: "Aankoop",
    CAPTURE: "Vastleggen",
    VOIDAUTHORISATION: "Autorisatie ongeldig maken",
    REFUND: "Terugbetaling",
    SUCCESSFUL: "Succesvol",
    NOTSUCCESSFUL: "Niet Succesvol",
    PENDING: "Verwerken",
  },
  ISSUE_A_REFUND: {
    label: "EEN TERUGBETALING MAKEN",
    button: {},
  },
  FormTemplate: {
    button: {
      Submit: "Indienen",
      Cancel: "Annuleren",
    },
  },
  ProductRiskRuleDrawerModal: {
    RISK_CONFIGURATION: "RISICO CONFIGURATIE",
    transaction_title: "Risicobeheersing voor",
    Statement: "Uitspraak",
    Trigger_Type: "Trigger Type",
    Action: "Actie",
    Configure_Rules: "Regels configureren",
    Blacklist: "Zwarte lijst",
    Threshold: "Drempel",
    Velocity: "Snelheid",
    Field: "Veld",
    TimeFrame: "Tijdsspanne",
    Value: "Waarde",
    currency: "SGD",
    button: {
      Submit: "Indienen",
      Cancel: "Annuleren",
    },
    helperText: {
      Threshold: "Waarde van het getal moet groter zijn dan 0",
      TimeFrame: "Waarde moet groter zijn dan 0",
      Value: "De waarde van het getal moet groter zijn dan 0",
    },
    option: {
      IssuingCountry: "Land van uitgifte",
      PAN: "PAN",
      IPAddress: "IP Adres",
      Field: "Max. transactiebedrag",
    },
    message: {
      Value: "De waarde van het getal mag maximaal twee decimalen zijn",
    },
  },
  TransactionCaptureDrawerBody: {
    ISSUE_A_CAPTURE: "EEN VASTLEGGEN UITGEVEN",
    fields: {
      Original_Amount: "Oorspronkelijke hoeveelheid",
      Remaining: "Overig",
      CaptureAmount: "Vastleggen Bedrag",
      Reference: "Referentie(Optioneel)",
      ConfirmCaptureCheckbox:
        "Ja, ik wil graag het bedrag vastleggen en ik begrijp dat deze actie niet omkeerbaar is. Ga verder om dit opnameproces te starten.",
    },
    helperText: {
      CaptureAmount: "Waarde van het getal moet groter zijn dan 0",
    },
    message: {
      ConfirmCaptureCheckbox:
        "Bevestig a.u.b. of u door wilt gaan met dit verzoek.",
      CaptureAmount: "De waarde van het getal mag maximaal twee decimalen zijn",
    },
    button: {
      Submit: "Indienen",
      Cancel: "Annuleren",
    },
  },
  EntityRiskConfiguration: {
    label: "Risicobeheersing voor",
    Risk_ColumnDefs: {
      Statement: "Uitspraak",
      Type: "TriggerType",
      POEntity: "PO Entiteit",
      action: "Actie",
      enabled: "Ingeschakeld",
    },
    Confirmation_Dialog: {
      title: "Verwijderen",
      heading: "Weet je het zeker?",
      content:
        "Wilt u deze record echt verwijderen? Dit proces kan niet ongedaan worden gemaakt.",
    },
    fields: {
      subsidiaryID: "PO Entiteit",
      statement: "Uitspraak",
      type: "TriggerType",
      fieldBlackList: "Veld",
      blacklisteditems: "Lijst met verboden landen",
      blacklistedbins: "Lijst met verboden prullenbakken",
      blacklistedpan: "Verboden Pan Lijst",
      blacklistedip: "Verboden IP-lijst",
      enabled: "Toestand",
    },
    form_title: "Regels configureren",
    file_upload: {
      label: "Verboden lijst",
    },
    button: {
      Submit: "Indienen",
      Cancel: "Annuleren",
    },
    breadcrumbs: {
      Home: "Huis",
      Rules: "Entiteitsrisicoregel",
    },
    options: {
      Singapore: "PO Singapore",
      Japan: "PO Japan",
      Mauritius: "PO Mauritius",
      Lithuania: "PO Lithuania",
      Malaysia: "PO Malaysia",
    },
    TriggerType: "Zwarte lijst",
    BinNumbers: "Baknummers",
    IP_Address: "IP Adres",
    IssuingCountry: "Land van uitgifte",
    PAN: "PAN",
    Status: {
      Enabled: "Ingeschakeld",
      Disabled: "Gehandicapt",
    },
  },
  PayByLinkConfiguration: {
    label: "Betalen via link",
    PayByLink_ColumnDefs: {
      action: "Actie",
      link: "Link-ID",
      generateddate: "Gegenereerde datum",
      amount: "Totaalbedrag",
      expirydate: "Vervaldatum",
      reminderdate: "Herinneringsdatum",
      status: "Toestand",
    },
    PayByLink_ColumnDefs_Buttons: {
      detail: "Details bekijken",
      view: "Bekijk link",
      copy: "Kopieer link",
    },
    Create_link_button: {
      label: "Nieuwe link maken",
    },
    Pbl_label: "Betaal via link voor",
    Link_fields: {
      reminder: "Ingestelde herinnering",
      expiry: "Vervaldatum",
      customerCheckbox: "Laat de klant de hoeveelheid aanpassen",
      promocodeCheckbox: "Promocodes toestaan",
    },
    Pbl_button: {
      Submit: "Koppeling genereren",
      Cancel: "Link deactiveren",
    },
    Generate_button: {
      copy: "Kopiëren",
      preview: "Voorbeeld",
    },
    Cart_Details: {
      Cart_label: "Winkelwagenitems",
      Cart_total: "Totaal:",
    },
    Confirmation_Dialog: {
      title: "Verwijderen",
      heading: "Weet je het zeker?",
      subHeading: "you want to leave this page?",
      content:
        "Changes you made to this link may not be saved. Please regenerate this link.",
      cancel: "STAY",
      submit: "EXIT",
    },
    Confirmation_Delete_Dialog: {
      title: "Delete",
      heading: "Are you sure?",
      content:
        "Do you really want to delete this record? This process can not be undone.",
      cancel: "Cancel",
      submit: "Submit",
    },
    PblDelete_item: {
      delete_item: "At least one item should be added to the cart.",
    },
    PblProduct_ColumnDefs: {
      action: "Actie",
      photo: "Foto",
      products: "Producten",
      quantity: "Hoeveelheid",
      price: "Prijs",
    },
    PblProduct_ColumnDefs_Buttons: {
      edit: "Item bewerken",
      delete: "Verwijder item",
    },
    Add_Product_button: {
      label: "Voeg een nieuw item toe",
    },
    Product_fields: {
      productname: "productnaam",
      quantity: "Hoeveelheid",
      price: "Prijs",
      upload: "Upload foto",
    },
    Product_button: {
      Submit: "Voeg toe aan winkelkar",
      Cancel: "Annuleren",
    },
    BreadCrumb: {
      Home: "Huis",
      paybylink: "Betalen via link",
    },
  },
  CreateThresholdEndAdornment: {
    Payment_Attempts: "Betaalpogingen",
  },
  CreateTimeFrameAdornment: {
    Months: "Maanden",
    Hours: "Uren",
    Minutes: "Minuten",
  },
  PoMidSchema: {
    QR: {
      Merchant_Name: "Naam verkoper",
      MCC: "handelaar categoriecode",
      Currency: "Munteenheid",
      Acquirer_MID: "verkrijger MID",
      DASMID: "DAS MID",
      Location_name: "Plaats naam",
      TerminalID: "Terminal ID",
      MerchantLogoURL: "handelaarslogo url",
      BillingDescriptor: "factureringsdescriptor",
      SchemeTypes: "schema typen",
      TransactionType: {
        label: "Transaction Type",
        value: {
          "3DSecure": "3D Veilig",
          "CVV2/CVC2": "CVV2/CVC2",
          "NonCVV2/CVC2": "Niet CVV2/CVC2",
          Non3DSecure: "Niet 3D-veilig",
        },
      },
      button: {
        Download_QR: "Downloaden QR",
      },
    },
  },
  AddNewProduct: {
    Tooltip: "Ga terug",
    Title: "Winkelwagenitems",
  },
  AG_GRID_LOCALE: {
    Page: "Bladzijde",
    to: "tot",
    of: "van",
    more: "meer",
  },
  NO_RECORD_FOUND: "No record found",
  NO_ROWS_TO_SHOW: "No rows to show",
  OnBoarding: {
    SideNav: {
      Complete: "Compleet",
      Your_registration: "Uw registratie",
      fill_out_required_fields: "Gelieve alle verplichte velden in te vullen",
      through_the_following_section: "via de volgende sectie:",
    },
    ABOUT_BUSSINESS: {
      About_Business: "Over uw bedrijf",
      Business_Location: "Bedrijfslocatie",
      Business_Name: "Bedrijfsnaam / winkelnaam",
      Unique_Entity_Number: "Registratie nummer",
      Monthly_Revenue: "maandelijkse omzet",
      Registered_Address: "Geregistreerd adres",
      Postcode: "postcode",
      City: "Stad",
      IncorporationDate: "Overnamedatum",
      Company_Type: "Bedrijfstype",
      NEXT: "DE VOLGENDE",
      Right_Panel_Text:
        "Houd er rekening mee dat u bij de laatste stap wordt gevraagd om uw bedrijfsregistratiecertificaat of organisatiedocumentatie te uploaden, dus bereid u hiermee voor. U kunt ook later terugkomen om uw registratie voort te zetten.",
    },
    CREATE_ACCOUNT: {
      LEFT_SUBTITLE:
        "Uw bedrijfsinformatie indienen en een verkopersaccount krijgen om betalingen te ontvangen",
      Create_Your: "Creëer je",
      Merchant_Account: "Handelaarsaccount",
      Complete_all_required_fields: "Vul alle verplichte velden in",
      First_Name: "Voornaam",
      Last_Name: "Achternaam",
      Phone_Number: "Telefoonnummer",
      Business_Location: "Bedrijfslocatie",
      Email_Address: "E-mailadres",
      Verify: "Verifiëren",
      Create_Password: "Maak een wachtwoord",
      I_agree_with_all: "ik ben het met iedereen eens",
      "terms_&_conditions": "algemene voorwaarden",
      CREATE_ACCOUNT: "MAAK ACCOUNT",
      Or_Sign_Up_with_your_social_media_account:
        "Of meld u aan met uw sociale media-account",
    },
    PAYMENT_METHODS: {
      Payment_Methods: "Betaalmethodes",
      Choose_payment_acceptance: "Kies een betalingsacceptatie",
      Scheme: "Schema",
      Processing_Currency: "Valuta verwerken",
      Processing_Website: "Website verwerken",
      Option: {
        "3DSecure": "3D Veilig",
        NON3DSecure: "NIET 3D Veilig",
        Recurring: "Terugkerend",
      },
      TypeOfIntegration: "Type integratie",
      DescribeTheProducts:
        "Beschrijf de producten of goederen en diensten die zullen worden verkocht",
      Countries: "Landen waar de belangrijkste transacties vandaan komen",
      countriesOptions: {
        Japan: "Japan",
        Singapore: "Singapore",
      },
      Average_Transaction_Amount: "Gemiddeld transactiebedrag",
      Maximum_Transaction_Amount: "Maximaal transactiebedrag",
      Total_Annual_Projection: "Totale jaarlijkse projectie",
      is_Store_Customer_Info: "Bewaart u de creditcardgegevens van uw klant?",
      is_Process_Card_trans: "Heeft u al eerder kaarttransacties verwerkt?",
      Refund_Rate: "Restitutietarief",
      Average: "Gemiddeld",
      Chargeback_Rate: "Terugboekingspercentage",
    },
    PAYOUT_DETAILS: {
      Payout_Details: "Uitbetalingsdetails",
      Account_Number: {
        label: "Rekeningnummer",
        tooltip:
          "Upload een kopie van uw bankafschrift dat in de afgelopen drie maanden is uitgegeven",
      },
      Settlement_Currency: "Afwikkelingsvaluta",
      Bank_Country: {
        Country_of_Bank: "Land van bank",
        option: {
          Japan: "Japan",
          Singapore: "Singapore",
        },
      },
      Bank_Name: "Banknaam",
      "How-often_to_paid": {
        label: "Hoe vaak betalen",
        option: {
          Daily: "Dagelijks",
          "Bi-Weekly": "Tweewekelijks",
          Weekly: "Wekelijks",
          Monthly: "Maandelijks",
        },
      },
      IBAN: "Internationaal bankrekeningnummer (IBAN)",
    },
    REGISTRATION_COMPLETED: {},
    STAKEHOLDERS_INFO: {
      About_your_Stakeholders: "Over uw belanghebbenden",
      columnDefs: {
        Action: "Actie",
        First_Name: "Voornaam",
        Last_Name: "Achternaam",
        Nationality: "Nationaliteit",
        Auth_Signatory: "Ondertekenaar van de authentificatie",
        UBO: "UBO",
        Add_New_Stakeholders: "Nieuwe belanghebbenden toevoegen",
      },
    },
    UPLOAD_DOCUMENTS: {
      Upload_Documents: "Documenten uploaden",
      Company_Registration_Certificate:
        "Bedrijfsregistratiecertificaat of organisatorische documentatie",
      No_order_doc: "Upload een document dat niet ouder is dan zes maanden",
      Drop_File_to_Upload: "Zet bestanden neer om te uploaden of",
      ID: "Identificatiedocument: geldig paspoort of nationale identiteitskaart",
      Bank_Statement:
        "Bankafschriften: Een kopie van een recent afschrift (niet ouder dan 3 maanden) voor alle Vereffeningsbankrekening(en)",
      Confirmation_Dialog:
        "Ik bevestig dat alle informatie die ik heb verstrekt waar en correct is. Nadat u uw aanmelding heeft ingediend, kunt u uw registratiegegevens niet meer wijzigen. Neem voor eventuele wijzigingen na indiening contact op met onze",
      Support_Team: "Ondersteuningsteam",
      button: {
        SUBMIT: "INDIENEN",
      },
    },
    ADD_NEW_STAKEHOLDER: {
      Add_New_StakeHolder: "Een nieuwe StakeHolder toevoegen",
      First_Name: "Voornaam",
      Last_Name: "Achternaam",
      DOB: "Geboortedatum",
      Nationality: "Nationaliteit",
      ID_Number: "ID nummer",
      Job_Title: "Functietitel",
      Rigth_to_Sign_Doc:
        "Krijgt deze persoon het recht om namens het bedrijf documenten te ondertekenen?",
      Ultimate_Beneficiary_Owner:
        "Is deze persoon een Uiteindelijke Begunstigde Eigenaar?",
      Percentage_in_Ownership: "Percentage in eigendom",
      Country_of_Residence: "land van verblijf",
      Postcode: "Postcode",
      City: "Stad",
      Address: "Adres",
      button: {
        SUBMIT: "INDIENEN",
        CANCEL: "ANNULEREN",
      },
      tooltip_title:
        "Upload een foto van het document-ID waarmee we de identiteit van elke belanghebbende kunnen valideren. Geaccepteerde identiteitsbewijzen: paspoort of nationale identiteitskaart.",
    },
  },

  ConfirmationDialogRaw: {
    actionButton: {
      title: "VERKLARING ONGELDIG",
      heading: "Weet je het zeker",
      content:
        "Weet u zeker dat u deze transactie ongedaan wilt maken? Dit proces kan niet ongedaan worden gemaakt.",
      Cancel: "Annuleren",
      Submit: "Indienen",
    },
    voidButton: {
      title: "VERKLARING ONGELDIG",
      heading: "Weet je het zeker",
      content:
        "Weet u zeker dat u deze transactie ongedaan wilt maken? Dit proces kan niet ongedaan worden gemaakt.",
      Cancel: "Annuleren",
      Submit: "Indienen",
    },
    riskRule: {
      title: "Verwijderen",
      heading: "Weet je het zeker?",
      content:
        "Wilt u deze record echt verwijderen? Dit proces kan niet ongedaan worden gemaakt.",
      cancel: "Annuleren",
      submit: "Indienen",
    },
  },
  RiskRule: {
    TabLabel: "RISICO CONFIGURATIE",
    tooltipTitle: "Nieuwe risicoregel toevoegen",
    Configure_Rules: "Regels configureren",
    Risk_Control_for: "Risicobeheersing voor",
    button: {
      Close: "Dichtbij",
    },
  },
  languagecode: "de",
};
export default common;
