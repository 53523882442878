import { useSelector } from "react-redux";
import loading from "../../assets/spinner-payops.gif";
import './style.css';

type isLoaderActiveType = Record<string,any>

export default function Loader({isLoading}:any) {
    const {isLoaderActive} = useSelector((state:isLoaderActiveType)=>state.loader);

  return (
    <>
    
      {( isLoading | isLoaderActive ) ? (
        <div className="loader-container">
          <img className="loader-gif" src={loading} alt="Loader" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
