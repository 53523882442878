export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const RESET_SUCCESSFULL = '/reset-password-successfull';
export const CHOOSE_ACCOUNT_TYPE_PAGE = '/onboarding/choose-account';
export const SIGN_UP_PAGE = '/onboarding/sign-up';
export const MENU = {
  DASHBOARD: "/dashboard",
  ONBOARDING: "/onboarding",
  TRANSACTIONS: "/transactions",
  MERCHANTS: "/accounts/merchants",
  RISK: "/rules/risk-management",
  ACQUIRERS: "/accounts/acquirers",
  STATEMENTS: "/finance/statements",
  DISPUTE: "/dispute-management/list",
  SALES_LEAD: "/onboarding/applications",
  PARTNER:"/partner",
  CONTACT_US:"/contact-us",
  RESELLER: '/reseller',
  LEGACY_TRANSACTIONS: "/legacy-transactions",
  LEGACY_DISPUTE: "/legacy-dispute-management/list",
  LEGACY_STATEMENTS: "/finance/legacy/statements",
  RESET: "/reset",
}; 

