import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useFetchWrapper as Api } from "../../utils";

// createAsyncThunk
export const getChargeBackDetails: any = createAsyncThunk(
    "dispute/getChargeBackDetails",
    async (apiPath) => {
        let data = await Api().get(apiPath);
        return data;
    }
);
export const getTransactionDetails: any = createAsyncThunk(
    "dispute/getTransactionDetails",
    async (apiPath) => {
        let data = await Api().get(apiPath);
        return data;
    }
);

// createSlice
export const disputeDrawerSlice = createSlice({
    name: "disputeDrawer",
    initialState: {
        chargeBackDetails: {},
        transactionDetails: {},
        loading: false,
        caseType:false,
        chargebackBtnName:'',
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getChargeBackDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getChargeBackDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.chargeBackDetails = action?.payload?.data;
            state.caseType=action?.payload?.data?.CaseType==="FirstChargeback"?true:action?.payload?.data?.CaseType==="SecondChargeback"?true:false;

        });
        builder.addCase(getChargeBackDetails.rejected, (state) => {
            state.loading = false;
        });

        // for Transaction Details Api

        builder.addCase(getTransactionDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTransactionDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.transactionDetails = action?.payload?.data;
        });
        builder.addCase(getTransactionDetails.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const chargeBackDetails = (state: any) =>
    state?.chargeBackDetails?.chargeBackDetails;

// export const {  } = disputeDrawerSlice.actions;

export default disputeDrawerSlice.reducer;
