export const UUID = 'uuid';
export const DRAWER = 'drawer';
export const TRANSACTION_DETAILS = 'transaction-details';
export const TRANSACTION_REFUND = 'transaction-refund';
export const TRANSACTION_CAPTURE = 'transaction-capture';
export const TRANSACTION_DETAILS_LINK =
  '/transactions?drawer=transaction-details&uuid=';
export const LEGACY_TRANSACTION_DETAILS_LINK =
  '/legacy-transactions?drawer=transaction-details&uuid=';
export const ACQUIRER_MID_DETAILS_LINK = 'acquirer-mid-drawer';
