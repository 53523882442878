import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_STATUS } from "../../components/constants";
import { useFetchWrapper as Api } from "../../utils";
import { AUTHENTICATION } from "../../components/constants/api-paths";


// createAsyncThunk
export const LoginDetails: any = createAsyncThunk(
  "login/LoginDetails",
  async (values) => {
    const loginDetails = await Api().post(`/${AUTHENTICATION.LOGIN_API}`, values);
    if ([HTTP_STATUS.OK].includes(loginDetails.statusCode)) {
      window.location.reload();
    }
    return loginDetails;
  }
);

// createSlice
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginDetails: {},
    loading: false,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LoginDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(LoginDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetails = action?.payload;
      state.message = action?.payload.message;
    });
    builder.addCase(LoginDetails.rejected, (state, action) => {
      state.loading = false;
      state.message = action?.payload.message;
    });
  },
});

export const loginDetails = (state: any) => state.login.loginDetails;
export const loginMessage = (state: any) => state.login.loginDetails.message;
export const loginStatus = (state: any) => state.login.loginDetails.statusCode;

export const loginData = (state: any) => state.login.loginDetails.data;

export const loading = (state: any) => state.login.loading;

export default loginSlice.reducer;
