export const RESET_EMAIL = 'resetEmail';

export const MAX_FILE_SIZE = 150000;

export const SELECTED_TIME_ZONE = 'timeZone';

export const DATE_TIME_FORMAT = 'dd MMMM yyyy, hh:mm:ss aaa';
export const DATE_FORMAT = 'dd MMMM yyyy';

export const MONTH_FORMAT = 'MM/yyyy';

export const CURRENCY_DATA_TYPE_FORMAT: any = {
  JPY: 'integer',
  SGD: 'float',
  USD: 'float',
  HKD: 'float',
  PHP: 'float'
};
export const EMAIL_VALIDATION_PATTERN: any =
  '^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';

export const USER_MANAGEMENT_EMAIL_VALIDATION_PATTERN: any =
  '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';

export const ACCESS_GROUPS: any = {
  INTERNAL: 'INTERNAL',
};

