import i18n from "../../i18n";

export type ICountries = {
  name: string;
  flag: string;
  label: string;
  value: string;
}[];

const countries: ICountries = [
  { name: i18n.t('CountryList.Afghanistan'), flag: '🇦🇫', value: 'AF', label: '+93' },
  { name: i18n.t('CountryList.ÅlandIslands'), flag: '🇦🇽', value: 'AX', label: '+358' },
  { name: i18n.t('CountryList.Albania'), flag: '🇦🇱', value: 'AL', label: '+355' },
  { name: i18n.t('CountryList.Algeria'), flag: '🇩🇿', value: 'DZ', label: '+213' },
  { name: i18n.t('CountryList.AmericanSamoa'), flag: '🇦🇸', value: 'AS', label: '+1684' },
  { name: i18n.t('CountryList.Andorra'), flag: '🇦🇩', value: 'AD', label: '+376' },
  { name: i18n.t('CountryList.Angola'), flag: '🇦🇴', value: 'AO', label: '+244' },
  { name: i18n.t('CountryList.Anguilla'), flag: '🇦🇮', value: 'AI', label: '+1264' },
  { name: i18n.t('CountryList.Antarctica'), flag: '🇦🇶', value: 'AQ', label: '+672' },
  { name: i18n.t('CountryList.AntiguaandBarbuda'), flag: '🇦🇬', value: 'AG', label: '+1268' },
  { name: i18n.t('CountryList.Argentina'), flag: '🇦🇷', value: 'AR', label: '+54' },
  { name: i18n.t('CountryList.Armenia'), flag: '🇦🇲', value: 'AM', label: '+374' },
  { name: i18n.t('CountryList.Aruba'), flag: '🇦🇼', value: 'AW', label: '+297' },
  { name: i18n.t('CountryList.Australia'), flag: '🇦🇺', value: 'AU', label: '+61' },
  { name: i18n.t('CountryList.Austria'), flag: '🇦🇹', value: 'AT', label: '+43' },
  { name: i18n.t('CountryList.Azerbaijan'), flag: '🇦🇿', value: 'AZ', label: '+994' },
  { name: i18n.t('CountryList.Bahamas'), flag: '🇧🇸', value: 'BS', label: '+1242' },
  { name: i18n.t('CountryList.Bahrain'), flag: '🇧🇭', value: 'BH', label: '+973' },
  { name: i18n.t('CountryList.Bangladesh'), flag: '🇧🇩', value: 'BD', label: '+880' },
  { name: i18n.t('CountryList.Barbados'), flag: '🇧🇧', value: 'BB', label: '+1246' },
  { name: i18n.t('CountryList.Belarus'), flag: '🇧🇾', value: 'BY', label: '+375' },
  { name: i18n.t('CountryList.Belgium'), flag: '🇧🇪', value: 'BE', label: '+32' },
  { name: i18n.t('CountryList.Belize'), flag: '🇧🇿', value: 'BZ', label: '+501' },
  { name: i18n.t('CountryList.Benin'), flag: '🇧🇯', value: 'BJ', label: '+229' },
  { name: i18n.t('CountryList.Bermuda'), flag: '🇧🇲', value: 'BM', label: '+1441' },
  { name: i18n.t('CountryList.Bhutan'), flag: '🇧🇹', value: 'BT', label: '+975' },
  { name: i18n.t('CountryList.Bolivia'), flag: '🇧🇴', value: 'BO', label: '+591' },
  { name: i18n.t('CountryList.BosniaandHerzegovina'), flag: '🇧🇦', value: 'BA', label: '+387' },
  { name: i18n.t('CountryList.Botswana'), flag: '🇧🇼', value: 'BW', label: '+267' },
  { name: i18n.t('CountryList.BouvetIsland'), flag: '🇧🇻', value: 'BV', label: '+47' },
  { name: i18n.t('CountryList.Brazil'), flag: '🇧🇷', value: 'BR', label: '+55' },
  { name: i18n.t('CountryList.BritishIndianOceanTerritory'), flag: '🇮🇴', value: 'IO', label: '+246' },
  { name: i18n.t('CountryList.BruneiDarussalam'), flag: '🇧🇳', value: 'BN', label: '+673' },
  { name: i18n.t('CountryList.Bulgaria'), flag: '🇧🇬', value: 'BG', label: '+359' },
  { name: i18n.t('CountryList.BurkinaFaso'), flag: '🇧🇫', value: 'BF', label: '+226' },
  { name: i18n.t('CountryList.Burundi'), flag: '🇧🇮', value: 'BI', label: '+257' },
  { name: i18n.t('CountryList.Cambodia'), flag: '🇰🇭', value: 'KH', label: '+855' },
  { name: i18n.t('CountryList.Cameroon'), flag: '🇨🇲', value: 'CM', label: '+237' },
  { name: i18n.t('CountryList.Canada'), flag: '🇨🇦', value: 'CA', label: '+1' },
  { name: i18n.t('CountryList.CapeVerde'), flag: '🇨🇻', value: 'CV', label: '+238' },
  { name: i18n.t('CountryList.CaymanIslands'), flag: '🇰🇾', value: 'KY', label: '+345' },
  { name: i18n.t('CountryList.CentralAfricanRepublic'), flag: '🇨🇫', value: 'CF', label: '+236' },
  { name: i18n.t('CountryList.Chad'), flag: '🇹🇩', value: 'TD', label: '+235' },
  { name: i18n.t('CountryList.Chile'), flag: '🇨🇱', value: 'CL', label: '+56' },
  { name: i18n.t('CountryList.China'), flag: '🇨🇳', value: 'CN', label: '+86' },
  { name: i18n.t('CountryList.ChristmasIsland'), flag: '🇨🇽', value: 'CX', label: '+61' },
  { name: i18n.t('CountryList.CocosKeelingIslands'), flag: '🇨🇨', value: 'CC', label: '+61' },
  { name: i18n.t('CountryList.Colombia'), flag: '🇨🇴', value: 'CO', label: '+57' },
  { name: i18n.t('CountryList.Comoros'), flag: '🇰🇲', value: 'KM', label: '+269' },
  { name: i18n.t('CountryList.Congo'), flag: '🇨🇬', value: 'CG', label: '+242' },
  // { name: i18n.t('CountryList.Congo'), flag: '🇨🇩', value: 'CD', label: '+243' },
  { name: i18n.t('CountryList.CookIslands'), flag: '🇨🇰', value: 'CK', label: '+682' },
  { name: i18n.t('CountryList.CostaRica'), flag: '🇨🇷', value: 'CR', label: '+506' },
  { name: i18n.t('CountryList.CoteDIvoire'), flag: '🇨🇮', value: 'CI', label: '+225' },
  { name: i18n.t('CountryList.Croatia'), flag: '🇭🇷', value: 'HR', label: '+385' },
  { name: i18n.t('CountryList.Cuba'), flag: '🇨🇺', value: 'CU', label: '+53' },
  { name: i18n.t('CountryList.Cyprus'), flag: '🇨🇾', value: 'CY', label: '+357' },
  { name: i18n.t('CountryList.CzechRepublic'), flag: '🇨🇿', value: 'CZ', label: '+420' },
  { name: i18n.t('CountryList.Denmark'), flag: '🇩🇰', value: 'DK', label: '+45' },
  { name: i18n.t('CountryList.Djibouti'), flag: '🇩🇯', value: 'DJ', label: '+253' },
  { name: i18n.t('CountryList.Dominica'), flag: '🇩🇲', value: 'DM', label: '+1767' },
  { name: i18n.t('CountryList.DominicanRepublic'), flag: '🇩🇴', value: 'DO', label: '+1849' },
  { name: i18n.t('CountryList.Ecuador'), flag: '🇪🇨', value: 'EC', label: '+593' },
  { name: i18n.t('CountryList.Egypt'), flag: '🇪🇬', value: 'EG', label: '+20' },
  { name: i18n.t('CountryList.ElSalvador'), flag: '🇸🇻', value: 'SV', label: '+503' },
  { name: i18n.t('CountryList.EquatorialGuinea'), flag: '🇬🇶', value: 'GQ', label: '+240' },
  { name: i18n.t('CountryList.Eritrea'), flag: '🇪🇷', value: 'ER', label: '+291' },
  { name: i18n.t('CountryList.Estonia'), flag: '🇪🇪', value: 'EE', label: '+372' },
  { name: i18n.t('CountryList.Ethiopia'), flag: '🇪🇹', value: 'ET', label: '+251' },
  { name: i18n.t('CountryList.FalklandIslandsMalvinas'), flag: '🇫🇰', value: 'FK', label: '+500' },
  { name: i18n.t('CountryList.FaroeIslands'), flag: '🇫🇴', value: 'FO', label: '+298' },
  { name: i18n.t('CountryList.Fiji'), flag: '🇫🇯', value: 'FJ', label: '+679' },
  { name: i18n.t('CountryList.Finland'), flag: '🇫🇮', value: 'FI', label: '+358' },
  { name: i18n.t('CountryList.France'), flag: '🇫🇷', value: 'FR', label: '+33' },
  { name: i18n.t('CountryList.FrenchGuiana'), flag: '🇬🇫', value: 'GF', label: '+594' },
  { name: i18n.t('CountryList.FrenchPolynesia'), flag: '🇵🇫', value: 'PF', label: '+689' },
  { name: i18n.t('CountryList.FrenchSouthernTerritories'), flag: '🇹🇫', value: 'TF', label: '+262' },
  { name: i18n.t('CountryList.Gabon'), flag: '🇬🇦', value: 'GA', label: '+241' },
  { name: i18n.t('CountryList.Gambia'), flag: '🇬🇲', value: 'GM', label: '+220' },
  { name: i18n.t('CountryList.Georgia'), flag: '🇬🇪', value: 'GE', label: '+995' },
  { name: i18n.t('CountryList.Germany'), flag: '🇩🇪', value: 'DE', label: '+49' },
  { name: i18n.t('CountryList.Ghana'), flag: '🇬🇭', value: 'GH', label: '+233' },
  { name: i18n.t('CountryList.Gibraltar'), flag: '🇬🇮', value: 'GI', label: '+350' },
  { name: i18n.t('CountryList.Greece'), flag: '🇬🇷', value: 'GR', label: '+30' },
  { name: i18n.t('CountryList.Greenland'), flag: '🇬🇱', value: 'GL', label: '+299' },
  { name: i18n.t('CountryList.Grenada'), flag: '🇬🇩', value: 'GD', label: '+1473' },
  { name: i18n.t('CountryList.Guadeloupe'), flag: '🇬🇵', value: 'GP', label: '+590' },
  { name: i18n.t('CountryList.Guam'), flag: '🇬🇺', value: 'GU', label: '+1671' },
  { name: i18n.t('CountryList.Guatemala'), flag: '🇬🇹', value: 'GT', label: '+502' },
  { name: i18n.t('CountryList.Guernsey'), flag: '🇬🇬', value: 'GG', label: '+44' },
  { name: i18n.t('CountryList.Guinea'), flag: '🇬🇳', value: 'GN', label: '+224' },
  { name: i18n.t('CountryList.GuineaBissau'), flag: '🇬🇼', value: 'GW', label: '+245' },
  { name: i18n.t('CountryList.Guyana'), flag: '🇬🇾', value: 'GY', label: '+592' },
  { name: i18n.t('CountryList.Haiti'), flag: '🇭🇹', value: 'HT', label: '+509' },
  { name: i18n.t('CountryList.HeardIslandandMcDonaldIslands'), flag: '🇭🇲', value: 'HM', label: '+672' },
  { name: i18n.t('CountryList.VaticanCityState'), flag: '🇻🇦', value: 'VA', label: '+379' },
  { name: i18n.t('CountryList.Honduras'), flag: '🇭🇳', value: 'HN', label: '+504' },
  { name: i18n.t('CountryList.HongKong'), flag: '🇭🇰', value: 'HK', label: '+852' },
  { name: i18n.t('CountryList.Hungary'), flag: '🇭🇺', value: 'HU', label: '+36' },
  { name: i18n.t('CountryList.Iceland'), flag: '🇮🇸', value: 'IS', label: '+354' },
  { name: i18n.t('CountryList.India'), flag: '🇮🇳', value: 'IN', label: '+91' },
  { name: i18n.t('CountryList.Indonesia'), flag: '🇮🇩', value: 'ID', label: '+62' },
  { name: i18n.t('CountryList.IranIslamicRepublicOf'), flag: '🇮🇷', value: 'IR', label: '+98' },
  { name: i18n.t('CountryList.Iraq'), flag: '🇮🇶', value: 'IQ', label: '+964' },
  { name: i18n.t('CountryList.Ireland'), flag: '🇮🇪', value: 'IE', label: '+353' },
  { name: i18n.t('CountryList.IsleofMan'), flag: '🇮🇲', value: 'IM', label: '+44' },
  { name: i18n.t('CountryList.Israel'), flag: '🇮🇱', value: 'IL', label: '+972' },
  { name: i18n.t('CountryList.Italy'), flag: '🇮🇹', value: 'IT', label: '+39' },
  { name: i18n.t('CountryList.Jamaica'), flag: '🇯🇲', value: 'JM', label: '+1876' },
  { name: i18n.t('CountryList.Japan'), flag: '🇯🇵', value: 'JP', label: '+81' },
  { name: i18n.t('CountryList.Jersey'), flag: '🇯🇪', value: 'JE', label: '+44' },
  { name: i18n.t('CountryList.Jordan'), flag: '🇯🇴', value: 'JO', label: '+962' },
  { name: i18n.t('CountryList.Kazakhstan'), flag: '🇰🇿', value: 'KZ', label: '+7' },
  { name: i18n.t('CountryList.Kenya'), flag: '🇰🇪', value: 'KE', label: '+254' },
  { name: i18n.t('CountryList.Kiribati'), flag: '🇰🇮', value: 'KI', label: '+686' },
  { name: i18n.t('CountryList.KoreaDemocraticPeopleRepublicof'), flag: '🇰🇵', value: 'KP', label: '+850' },
  { name: i18n.t('CountryList.KoreaRepublicof'), flag: '🇰🇷', value: 'KR', label: '+82' },
  // { name: i18n.t('CountryList.Kosovo'), flag: '🇽🇰', value: 'XK', label: '+383' },
  { name: i18n.t('CountryList.Kuwait'), flag: '🇰🇼', value: 'KW', label: '+965' },
  { name: i18n.t('CountryList.Kyrgyzstan'), flag: '🇰🇬', value: 'KG', label: '+996' },
  // { name: i18n.t('CountryList.Laos'), flag: '🇱🇦', value: 'LA', label: '+856' },
  { name: i18n.t('CountryList.Latvia'), flag: '🇱🇻', value: 'LV', label: '+371' },
  { name: i18n.t('CountryList.Lebanon'), flag: '🇱🇧', value: 'LB', label: '+961' },
  { name: i18n.t('CountryList.Lesotho'), flag: '🇱🇸', value: 'LS', label: '+266' },
  { name: i18n.t('CountryList.Liberia'), flag: '🇱🇷', value: 'LR', label: '+231' },
  { name: i18n.t('CountryList.LibyanArabJamahiriya'), flag: '🇱🇾', value: 'LY', label: '+218' },
  { name: i18n.t('CountryList.Liechtenstein'), flag: '🇱🇮', value: 'LI', label: '+423' },
  { name: i18n.t('CountryList.Lithuania'), flag: '🇱🇹', value: 'LT', label: '+370' },
  { name: i18n.t('CountryList.Luxembourg'), flag: '🇱🇺', value: 'LU', label: '+352' },
  { name: i18n.t('CountryList.Macao'), flag: '🇲🇴', value: 'MO', label: '+853' },
  { name: i18n.t('CountryList.MacedoniaTheFormerYugoslavRepublicof'), flag: '🇲🇰', value: 'MK', label: '+389' },
  { name: i18n.t('CountryList.Madagascar'), flag: '🇲🇬', value: 'MG', label: '+261' },
  { name: i18n.t('CountryList.Malawi'), flag: '🇲🇼', value: 'MW', label: '+265' },
  { name: i18n.t('CountryList.Malaysia'), flag: '🇲🇾', value: 'MY', label: '+60' },
  { name: i18n.t('CountryList.Maldives'), flag: '🇲🇻', value: 'MV', label: '+960' },
  { name: i18n.t('CountryList.Mali'), flag: '🇲🇱', value: 'ML', label: '+223' },
  { name: i18n.t('CountryList.Malta'), flag: '🇲🇹', value: 'MT', label: '+356' },
  { name: i18n.t('CountryList.MarshalIslands'), flag: '🇲🇭', value: 'MH', label: '+692' },
  { name: i18n.t('CountryList.Martinique'), flag: '🇲🇶', value: 'MQ', label: '+596' },
  { name: i18n.t('CountryList.Mauritania'), flag: '🇲🇷', value: 'MR', label: '+222' },
  { name: i18n.t('CountryList.Mauritius'), flag: '🇲🇺', value: 'MU', label: '+230' },
  { name: i18n.t('CountryList.Mayotte'), flag: '🇾🇹', value: 'YT', label: '+262' },
  { name: i18n.t('CountryList.Mexico'), flag: '🇲🇽', value: 'MX', label: '+52' },
  { name: i18n.t('CountryList.MicronesiaFederatedStatesof'), flag: '🇫🇲', value: 'FM', label: '+691' },
  { name: i18n.t('CountryList.MoldovaRepublicof'), flag: '🇲🇩', value: 'MD', label: '+373' },
  { name: i18n.t('CountryList.Monaco'), flag: '🇲🇨', value: 'MC', label: '+377' },
  { name: i18n.t('CountryList.Mongolia'), flag: '🇲🇳', value: 'MN', label: '+976' },
  // { name: i18n.t('CountryList.Montenegro'), flag: '🇲🇪', value: 'ME', label: '+382' },
  { name: i18n.t('CountryList.Montserrat'), flag: '🇲🇸', value: 'MS', label: '+1664' },
  { name: i18n.t('CountryList.Morocco'), flag: '🇲🇦', value: 'MA', label: '+212' },
  { name: i18n.t('CountryList.Mozambique'), flag: '🇲🇿', value: 'MZ', label: '+258' },
  { name: i18n.t('CountryList.Myanmar'), flag: '🇲🇲', value: 'MM', label: '+95' },
  { name: i18n.t('CountryList.Namibia'), flag: '🇳🇦', value: 'NA', label: '+264' },
  { name: i18n.t('CountryList.Nauru'), flag: '🇳🇷', value: 'NR', label: '+674' },
  { name: i18n.t('CountryList.Nepal'), flag: '🇳🇵', value: 'NP', label: '+977' },
  { name: i18n.t('CountryList.Netherlands'), flag: '🇳🇱', value: 'NL', label: '+31' },
  { name: i18n.t('CountryList.NetherlandsAntilles'), flag: '', value: 'AN', label: '+599' },
  { name: i18n.t('CountryList.NewCaledonia'), flag: '🇳🇨', value: 'NC', label: '+687' },
  { name: i18n.t('CountryList.NewZealand'), flag: '🇳🇿', value: 'NZ', label: '+64' },
  { name: i18n.t('CountryList.Nicaragua'), flag: '🇳🇮', value: 'NI', label: '+505' },
  { name: i18n.t('CountryList.Niger'), flag: '🇳🇪', value: 'NE', label: '+227' },
  { name: i18n.t('CountryList.Nigeria'), flag: '🇳🇬', value: 'NG', label: '+234' },
  { name: i18n.t('CountryList.Niue'), flag: '🇳🇺', value: 'NU', label: '+683' },
  { name: i18n.t('CountryList.NorfolkIsland'), flag: '🇳🇫', value: 'NF', label: '+672' },
  { name: i18n.t('CountryList.NorthernMarianaIslands'), flag: '🇲🇵', value: 'MP', label: '+1670' },
  { name: i18n.t('CountryList.Norway'), flag: '🇳🇴', value: 'NO', label: '+47' },
  { name: i18n.t('CountryList.Oman'), flag: '🇴🇲', value: 'OM', label: '+968' },
  { name: i18n.t('CountryList.Pakistan'), flag: '🇵🇰', value: 'PK', label: '+92' },
  { name: i18n.t('CountryList.Palau'), flag: '🇵🇼', value: 'PW', label: '+680' },
  { name: i18n.t('CountryList.PalestinianTerritoryOccupied'), flag: '🇵🇸', value: 'PS', label: '+970' },
  { name: i18n.t('CountryList.Panama'), flag: '🇵🇦', value: 'PA', label: '+507' },
  { name: i18n.t('CountryList.PapuaNewGuinea'), flag: '🇵🇬', value: 'PG', label: '+675' },
  { name: i18n.t('CountryList.Paraguay'), flag: '🇵🇾', value: 'PY', label: '+595' },
  { name: i18n.t('CountryList.Peru'), flag: '🇵🇪', value: 'PE', label: '+51' },
  { name: i18n.t('CountryList.Philippines'), flag: '🇵🇭', value: 'PH', label: '+63' },
  { name: i18n.t('CountryList.Pitcairn'), flag: '🇵🇳', value: 'PN', label: '+64' },
  { name: i18n.t('CountryList.Poland'), flag: '🇵🇱', value: 'PL', label: '+48' },
  { name: i18n.t('CountryList.Portugal'), flag: '🇵🇹', value: 'PT', label: '+351' },
  { name: i18n.t('CountryList.PuertoRico'), flag: '🇵🇷', value: 'PR', label: '+1939' },
  { name: i18n.t('CountryList.Qatar'), flag: '🇶🇦', value: 'QA', label: '+974' },
  { name: i18n.t('CountryList.Romania'), flag: '🇷🇴', value: 'RO', label: '+40' },
  { name: i18n.t('CountryList.RussianFederation'), flag: '🇷🇺', value: 'RU', label: '+7' },
  { name: i18n.t('CountryList.Rwanda'), flag: '🇷🇼', value: 'RW', label: '+250' },
  { name: i18n.t('CountryList.Reunion'), flag: '🇷🇪', value: 'RE', label: '+262' },
  // { name: i18n.t('CountryList.SaintBarthelemy'), flag: '🇧🇱', value: 'BL', label: '+590' },
  { name: i18n.t('CountryList.SaintHelena'), flag: '🇸🇭', value: 'SH', label: '+290' },
  { name: i18n.t('CountryList.SaintKittsandNevis'), flag: '🇰🇳', value: 'KN', label: '+1869' },
  { name: i18n.t('CountryList.SaintLucia'), flag: '🇱🇨', value: 'LC', label: '+1758' },
  // { name: i18n.t('CountryList.SaintMartin'), flag: '🇲🇫', value: 'MF', label: '+590' },
  { name: i18n.t('CountryList.SaintPierreandMiquelon'), flag: '🇵🇲', value: 'PM', label: '+508' },
  { name: i18n.t('CountryList.SaintVincentandtheGrenadines'), flag: '🇻🇨', value: 'VC', label: '+1784' },
  { name: i18n.t('CountryList.Samoa'), flag: '🇼🇸', value: 'WS', label: '+685' },
  { name: i18n.t('CountryList.SanMarino'), flag: '🇸🇲', value: 'SM', label: '+378' },
  { name: i18n.t('CountryList.SaoTomeandPrincipe'), flag: '🇸🇹', value: 'ST', label: '+239' },
  { name: i18n.t('CountryList.SaudiArabia'), flag: '🇸🇦', value: 'SA', label: '+966' },
  { name: i18n.t('CountryList.Senegal'), flag: '🇸🇳', value: 'SN', label: '+221' },
  { name: i18n.t('CountryList.SerbiaandMontenegro'), flag: '🇷🇸', value: 'RS', label: '+381' },
  { name: i18n.t('CountryList.Seychelles'), flag: '🇸🇨', value: 'SC', label: '+248' },
  { name: i18n.t('CountryList.SierraLeone'), flag: '🇸🇱', value: 'SL', label: '+232' },
  { name: i18n.t('CountryList.Singapore'), flag: '🇸🇬', value: 'SG', label: '+65' },
  { name: i18n.t('CountryList.Slovakia'), flag: '🇸🇰', value: 'SK', label: '+421' },
  { name: i18n.t('CountryList.Slovenia'), flag: '🇸🇮', value: 'SI', label: '+386' },
  { name: i18n.t('CountryList.SolomonIslands'), flag: '🇸🇧', value: 'SB', label: '+677' },
  { name: i18n.t('CountryList.Somalia'), flag: '🇸🇴', value: 'SO', label: '+252' },
  { name: i18n.t('CountryList.SouthAfrica'), flag: '🇿🇦', value: 'ZA', label: '+27' },
  { name: i18n.t('CountryList.Sudan'), flag: '🇸🇸', value: 'SS', label: '+211' },
  { name: i18n.t('CountryList.SouthGeorgiaandtheSouthSandwichIslands'), flag: '🇬🇸', value: 'GS', label: '+500' },
  { name: i18n.t('CountryList.Spain'), flag: '🇪🇸', value: 'ES', label: '+34' },
  { name: i18n.t('CountryList.SriLanka'), flag: '🇱🇰', value: 'LK', label: '+94' },
  // { name: i18n.t('CountryList.Sudan'), flag: '🇸🇩', value: 'SD', label: '+249' },
  { name: i18n.t('CountryList.Suriname'), flag: '🇸🇷', value: 'SR', label: '+597' },
  { name: i18n.t('CountryList.SvalbardandJanMayen'), flag: '🇸🇯', value: 'SJ', label: '+47' },
  //{ name: i18n.t('CountryList.Eswatini'), flag: '🇸🇿', value: 'SZ', label: '+268' },
  { name: i18n.t('CountryList.Sweden'), flag: '🇸🇪', value: 'SE', label: '+46' },
  { name: i18n.t('CountryList.Switzerland'), flag: '🇨🇭', value: 'CH', label: '+41' },
  { name: i18n.t('CountryList.SyrianArabRepublic'), flag: '🇸🇾', value: 'SY', label: '+963' },
  { name: i18n.t('CountryList.TaiwanProvinceofChina'), flag: '🇹🇼', value: 'TW', label: '+886' },
  { name: i18n.t('CountryList.Tajikistan'), flag: '🇹🇯', value: 'TJ', label: '+992' },
  { name: i18n.t('CountryList.TanzaniaUnitedRepublicof'), flag: '🇹🇿', value: 'TZ', label: '+255' },
  { name: i18n.t('CountryList.Thailand'), flag: '🇹🇭', value: 'TH', label: '+66' },
  { name: i18n.t('CountryList.TimorLeste'), flag: '🇹🇱', value: 'TL', label: '+670' },
  { name: i18n.t('CountryList.Togo'), flag: '🇹🇬', value: 'TG', label: '+228' },
  { name: i18n.t('CountryList.Tokelau'), flag: '🇹🇰', value: 'TK', label: '+690' },
  { name: i18n.t('CountryList.Tonga'), flag: '🇹🇴', value: 'TO', label: '+676' },
  { name: i18n.t('CountryList.TrinidadandTobago'), flag: '🇹🇹', value: 'TT', label: '+1868' },
  { name: i18n.t('CountryList.Tunisia'), flag: '🇹🇳', value: 'TN', label: '+216' },
  { name: i18n.t('CountryList.Turkey'), flag: '🇹🇷', value: 'TR', label: '+90' },
  { name: i18n.t('CountryList.Turkmenistan'), flag: '🇹🇲', value: 'TM', label: '+993' },
  { name: i18n.t('CountryList.TurksandCaicosIslands'), flag: '🇹🇨', value: 'TC', label: '+1649' },
  { name: i18n.t('CountryList.Tuvalu'), flag: '🇹🇻', value: 'TV', label: '+688' },
  { name: i18n.t('CountryList.Uganda'), flag: '🇺🇬', value: 'UG', label: '+256' },
  { name: i18n.t('CountryList.Ukraine'), flag: '🇺🇦', value: 'UA', label: '+380' },
  { name: i18n.t('CountryList.UnitedArabEmirates'), flag: '🇦🇪', value: 'AE', label: '+971' },
  { name: i18n.t('CountryList.UnitedKingdom'), flag: '🇬🇧', value: 'GB', label: '+44' },
  { name: i18n.t('CountryList.UnitedStates'), flag: '🇺🇸', value: 'US', label: '+1' },
  { name: i18n.t('CountryList.Uruguay'), flag: '🇺🇾', value: 'UY', label: '+598' },
  { name: i18n.t('CountryList.Uzbekistan'), flag: '🇺🇿', value: 'UZ', label: '+998' },
  { name: i18n.t('CountryList.Vanuatu'), flag: '🇻🇺', value: 'VU', label: '+678' },
  { name: i18n.t('CountryList.Venezuela'), flag: '🇻🇪', value: 'VE', label: '+58' },
  { name: i18n.t('CountryList.VietNam'), flag: '🇻🇳', value: 'VN', label: '+84' },
  { name: i18n.t('CountryList.VirginIslandsBritish'), flag: '🇻🇬', value: 'VG', label: '+1284' },
  { name: i18n.t('CountryList.VirginIslandsUS'), flag: '🇻🇮', value: 'VI', label: '+1340' },
  { name: i18n.t('CountryList.WallisandFutuna'), flag: '🇼🇫', value: 'WF', label: '+681' },
  { name: i18n.t('CountryList.Yemen'), flag: '🇾🇪', value: 'YE', label: '+967' },
  { name: i18n.t('CountryList.Zambia'), flag: '🇿🇲', value: 'ZM', label: '+260' },
  { name: i18n.t('CountryList.Zimbabwe'), flag: '🇿🇼', value: 'ZW', label: '+263' },
];
export default countries;
