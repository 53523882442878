import i18n from "../../../../../i18n";

export const HOLIDAY_TYPE:any = [
    {
    label: i18n.t('Holiday.type.PublicHoliday'),
    value: "PH"
    },
    {
    label: i18n.t('Holiday.type.EmergencyOff'),
    value: "EO"
    }
];

export const HOLIDAY_COUNTRIES_LIST :any=[
    {
      label: "Mauritius",
      value: "MU"
    },
    {
      label: "Singapore",
      value: "SG"
    },
    {
      label: "Malaysia",
      value: "MY"
    },
    {
      label: "Japan",
      value: "JP"
    },
    {
      label: "Hong Kong",
      value: "HK"
    },
    {
      label: "United Kingdom",
      value: "GB"
    }
  ];

export const DateComparisionOperators = {
  LessThanToday:"LessThanToday",
  LessThanOrEqualsToToday:"LessThanOrEqualsToToday",
  EqualsToToday:"EqualsToToday",
  GreaterThanToday:"GreaterThanToday",
  GreaterThanOrEqualsToToday:"GreaterThanOrEqualsToToday"
};

  export const holidayTypes = {
    EO: "EO",
    PH: "PH"
};