import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { useFetchWrapper as Api } from "../../utils";
import { getUserProfile } from '../../pages/authentication/login/components/api';
import { CONFIGURATION_API } from '../../components/constants/api-paths';
import { useFetchWrapper as Api } from '../../utils';
import { HTTP_STATUS } from '../../components/constants';


// const baseUrl = `${process.env.REACT_APP_API_URL}`;

// createAsyncThunk

export const saveProfile: any = createAsyncThunk(
  'auth/saveProfile',
  async values => {
    // const { data } = await Api().post(`/auth/signIn/`, values);
    const data = await getUserProfile(values);
    return data;
  },
);

function transformChargebackReasonCode(input: any) {
  const output: any = {};

  for (const key in input) {
    // if (input?.hasOwnProperty(key)) {
    output[key] = input[key].map((item: any) => {
      const labelValue = `${item.ReasonCode} ${item.ReasonCodeDescription}`;
      return {
        "label": labelValue,
        "value": labelValue
      };
    });
    // }
  }

  return output;
}
export const getDasMidOptions: any = createAsyncThunk(
  'get/getDasMidOptions',
  async (values, { dispatch, getState }) => {
    const store: any = getState();
    const logginInAsMerchant = store?.auth?.profile?.Groups?.includes("CUSTOMER");
    const { accessLevel } = store?.auth?.profile;
    const response = await Api().get(CONFIGURATION_API);
    if ([HTTP_STATUS.OK]?.includes(response?.statusCode)) {

      const dasmidOptions = response?.data?.merchantData?.map(({ DASMID }: any) => {
        return DASMID;
      })?.flat(1) || [];
      const v2DasmidOptions = response?.data?.merchantData?.map(({ V2DASMID }: any) => {
        return V2DASMID;
      })?.flat(1)?.filter((item: any) => item) || [];
      const IsV2Merchant = response?.data?.merchantData?.find((item: any) => item.IsV2Merchant);

      // const merchantDetails = response?.data?.merchantData[0];
      if (v2DasmidOptions && v2DasmidOptions?.length > 0 && IsV2Merchant) {
        if (logginInAsMerchant && accessLevel === "ADMIN") {

          dispatch(setIsV2Merchant(true));
        }
        else if (!logginInAsMerchant) {
          dispatch(setIsV2Merchant(true));

        }
        else {
          dispatch(setIsV2Merchant(false));

        }

      }
      const dasmidList: any = dasmidOptions && dasmidOptions?.length > 0 ? [...new Set([...dasmidOptions])] : "";
      localStorage.setItem("dasmidOptions", dasmidList);
      const v2dasmidList: any = v2DasmidOptions && v2DasmidOptions?.length > 0 ? [...new Set([...v2DasmidOptions])] : "";
      localStorage.setItem("v2DasmidOptions", v2dasmidList);
      if (!logginInAsMerchant) {
        const acquirerCode: any = response?.data?.acquirers || "";
        localStorage.setItem("acquirerCode", acquirerCode);
        const acquirerMIDData: any = Object.values(response?.data?.acquirerMIDData)?.flat(1);
        localStorage.setItem("acquirerMIDData", acquirerMIDData);
        const reasonCodeData = transformChargebackReasonCode(response?.data?.chargebackReasonCode);
        dispatch(setReasonCodeList(reasonCodeData));
        const legalNamesList = response?.data?.merchantData?.map(({ LegalName }: any) => {
          return LegalName;
        });
        localStorage.setItem("legalNamesList", legalNamesList);
      }
      return response;
    } else {
      localStorage.setItem("dasmidOptions", "");
      localStorage.setItem("v2DasmidOptions", "");
      localStorage.setItem("legalNamesList", "");

      return response;
    }
  }
);

const initialState: any = {
  profile: undefined,
  userType: '',
  isMerchant: false,
  isReseller: false,
  isV2Merchant: false,
  reasonCodeList: [],
  firstTimeLogin: false,
  allReasonCodes: [],
};

// createSlice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginDetails: (state, action) => {
      state.profile = action?.payload;
      if (action?.payload?.Groups?.includes('GUEST')) {
        state.userType = 'MERCHANT';
        state.isMerchant = true;
      } else if (action?.payload?.Groups?.includes('GUESTTHIRDPARTY')) {
        state.userType = 'RESELLER';
        state.isReseller = true;
      } else {
        state.userType = '';
      }
    },
    resetProfile: state => {
      state.profile = undefined;
      state.userType = '';
      state.isMerchant = false;
      state.isReseller = false;
      state.isV2Merchant = false;
      state.firstTimeLogin = false;
      state.reasonCodeList = [];
      state.allReasonCodes = [];
    },
    setIsV2Merchant: (state, action) => {
      state.isV2Merchant = action.payload;
    },
    setReasonCodeList: (state, action) => {
      state.reasonCodeList = action.payload;
      state.allReasonCodes = Object.values(action.payload)?.flat(1);
    },
    setFirstTimeLogin: (state, action) => {
      state.firstTimeLogin = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(saveProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.firstTimeLogin = action.payload?.reset || false;
      if (action?.payload?.Groups?.includes('GUEST')) {
        state.userType = 'MERCHANT';
        state.isMerchant = true;
      } else if (action?.payload?.Groups?.includes('GUESTTHIRDPARTY')) {
        state.userType = 'RESELLER';
        state.isReseller = true;
      } else {
        state.userType = '';
      }
    });
  },
});
export const authProfile = (state: any) => state?.auth?.profile;
export const authToken = (state: any) => state?.auth?.profile?.token;
export const isV2Merchant = (state: any) => state?.auth?.isV2Merchant;
export const reasonCodeList = (state: any) => state?.auth?.reasonCodeList;
export const allReasonCodes = (state: any) => state?.auth?.allReasonCodes;
export const firstTimeLogin = (state: any) => state?.auth?.firstTimeLogin;
export const authGroups = (state: any) => state?.auth?.profile?.Groups;
export const authEmail = (state: any) => state?.auth?.profile?.email;
export const authName = (state: any) => state?.auth?.profile?.name;
export const authAppLevel = (state: any) => state?.auth?.profile?.appLevel;
export const authUserRole = (state: any) => state?.auth?.profile?.accessLevel;
export const authSubsidiaries = (state: any) =>
  state?.auth?.profile?.subsidiaries;
export const authLastLogin = (state: any) =>
  state?.auth?.profile?.auth_time * 1000;
export const { resetProfile, setLoginDetails, setIsV2Merchant, setReasonCodeList, setFirstTimeLogin } = authSlice.actions;
export default authSlice.reducer;

