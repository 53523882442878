type MCCType = {
  d: string;
  value: string;
}[];
const MCC: MCCType = [
  {
    d: "0742 Veterinary Services",
    value: "0742",
  },
  {
    d: "0763 Agricultural Co-operatives",
    value: "0763",
  },
  {
    d: "0780 Landscaping and Horticultural Services",
    value: "0780",
  },
  {
    d: "1520 General Contractors - Residential and Commercial",
    value: "1520",
  },
  {
    d: "1711 Heating, Plumbing, and Air Conditioning Contractors",
    value: "1711",
  },
  {
    d: "1731 Electrical Contractors",
    value: "1731",
  },
  {
    d: "1740 Masonry, Stonework, Tile Setting, Plastering and Insulation Contractors",
    value: "1740",
  },
  {
    d: "1750 Carpentry Contractors",
    value: "1750",
  },
  {
    d: "1761 Roofing, Siding, and Sheet Metal Work Contractors",
    value: "1761",
  },
  {
    d: "1771 Concrete Work Contractors",
    value: "1771",
  },
  {
    d: "1799 Special Trade Contractors (Not Elsewhere Classified)",
    value: "1799",
  },
  {
    d: "2741 Miscellaneous Publishing and Printing (B2B)",
    value: "2741",
  },
  {
    d: "2791 Typesetting, Plate Making and Related Services (B2B)",
    value: "2791",
  },
  {
    d: "2842 Specialty Cleaning, Polishing and Sanitation Preparations (B2B)",
    value: "2842",
  },
  {
    d: "3000 UNITED AIRLINES",
    value: "3000",
  },
  {
    d: "3001 AMERICAN AIRLINES",
    value: "3001",
  },
  {
    d: "3002 PAN AMERICAN",
    value: "3002",
  },
  {
    d: "3003 EUROFLY AIRLINES",
    value: "3003",
  },
  {
    d: "3004 DRAGON AIRLINES",
    value: "3004",
  },
  {
    d: "3005 BRITISH AIRWAYS",
    value: "3005",
  },
  {
    d: "3006 JAPAN AIRLINES",
    value: "3006",
  },
  {
    d: "3007 AIR FRANCE",
    value: "3007",
  },
  {
    d: "3008 LUFTHANSA",
    value: "3008",
  },
  {
    d: "3009 AIR CANADA",
    value: "3009",
  },
  {
    d: "3010 KLM (ROYAL DUTCH AIRLINES)",
    value: "3010",
  },
  {
    d: "3011 AEROFLOT",
    value: "3011",
  },
  {
    d: "3012 QANTAS",
    value: "3012",
  },
  {
    d: "3013 ALITALIA",
    value: "3013",
  },
  {
    d: "3014 SAUDI ARABIAN AIRLINES",
    value: "3014",
  },
  {
    d: "3015 SWISS INTERNATIONAL AIRLINES",
    value: "3015",
  },
  {
    d: "3016 SAS",
    value: "3016",
  },
  {
    d: "3017 SOUTH AFRICAN AIRWAYS",
    value: "3017",
  },
  {
    d: "3018 VARIG (BRAZIL)",
    value: "3018",
  },
  {
    d: "3020 AIR-INDIA",
    value: "3020",
  },
  {
    d: "3021 AIR ALGERIE",
    value: "3021",
  },
  {
    d: "3022 PHILIPPINE AIRLINES",
    value: "3022",
  },
  {
    d: "3023 MEXICANA",
    value: "3023",
  },
  {
    d: "3024 PAKISTAN INTERNATIONAL",
    value: "3024",
  },
  {
    d: "3025 AIR NEW ZEALAND",
    value: "3025",
  },
  {
    d: "3026 EMIRATES AIRLINES",
    value: "3026",
  },
  {
    d: "3027 UTA/INTERAIR",
    value: "3027",
  },
  {
    d: "3028 AIR MALTA",
    value: "3028",
  },
  {
    d: "3029 SN BRUSSELS AIRLINES",
    value: "3029",
  },
  {
    d: "3030 AEROLINEAS ARGENTINAS",
    value: "3030",
  },
  {
    d: "3031 OLYMPIC AIRWAYS",
    value: "3031",
  },
  {
    d: "3032 EL AL",
    value: "3032",
  },
  {
    d: "3033 ANSETT AIRLINES",
    value: "3033",
  },
  {
    d: "3034 ETIHAD AIRWAYS",
    value: "3034",
  },
  {
    d: "3035 TAP (PORTUGAL)",
    value: "3035",
  },
  {
    d: "3036 VASP (BRAZIL)",
    value: "3036",
  },
  {
    d: "3037 EGYPTAIR",
    value: "3037",
  },
  {
    d: "3038 KUWAIT AIRWAYS",
    value: "3038",
  },
  {
    d: "3039 AVIANCA",
    value: "3039",
  },
  {
    d: "3040 GULF AIR (BAHRAIN)",
    value: "3040",
  },
  {
    d: "3041 BALKAN-BULGARIAN AIRLINES",
    value: "3041",
  },
  {
    d: "3042 Finnair-FINNAIR",
    value: "3042",
  },
  {
    d: "3043 AER LINGUS",
    value: "3043",
  },
  {
    d: "3044 AIR LANKA",
    value: "3044",
  },
  {
    d: "3045 NIGERIA AIRWAYS",
    value: "3045",
  },
  {
    d: "3046 CRUZEIRO DO SUL (BRAZIL)",
    value: "3046",
  },
  {
    d: "3047 THY (TURKEY)",
    value: "3047",
  },
  {
    d: "3048 ROYAL AIR MAROC",
    value: "3048",
  },
  {
    d: "3049 TUNIS AIR",
    value: "3049",
  },
  {
    d: "3050 ICELANDAIR",
    value: "3050",
  },
  {
    d: "3051 Austrian Airlines-AUSTRIAN",
    value: "3051",
  },
  {
    d: "3052 LAN AIRLINES",
    value: "3052",
  },
  {
    d: "3053 AVIACO (SPAIN)",
    value: "3053",
  },
  {
    d: "3054 LADECO (CHILE)",
    value: "3054",
  },
  {
    d: "3055 LAB (BOLIVIA)",
    value: "3055",
  },
  {
    d: "3056 JET AIRWAYS",
    value: "3056",
  },
  {
    d: "3057 VIRGIN AMERICA",
    value: "3057",
  },
  {
    d: "3058 DELTA",
    value: "3058",
  },
  {
    d: "3059 DBA AIRLINES",
    value: "3059",
  },
  {
    d: "3060 NORTHWEST",
    value: "3060",
  },
  {
    d: "3061 CONTINENTAL",
    value: "3061",
  },
  {
    d: "3062 HAPAG-LLOYD EXPRESS AIRLINES",
    value: "3062",
  },
  {
    d: "3063 US AIRWAYS",
    value: "3063",
  },
  {
    d: "3064 ADRIA AIRWAYS",
    value: "3064",
  },
  {
    d: "3065 AIRINTER",
    value: "3065",
  },
  {
    d: "3066 SOUTHWEST",
    value: "3066",
  },
  {
    d: "3067 VANGUARD AIRLINES",
    value: "3067",
  },
  {
    d: "3068 AIR ASTANA",
    value: "3068",
  },
  {
    d: "3069 SUN COUNTRY AIRLINES",
    value: "3069",
  },
  {
    d: "3071 AIR BRITISH COLUMBIA",
    value: "3071",
  },
  {
    d: "3072 CEBU PACIFIC AIRLINES",
    value: "3072",
  },
  {
    d: "3075 SINGAPORE AIRLINES",
    value: "3075",
  },
  {
    d: "3076 AEROMEXICO",
    value: "3076",
  },
  {
    d: "3077 THAI AIRWAYS",
    value: "3077",
  },
  {
    d: "3078 CHINA AIRLINES",
    value: "3078",
  },
  {
    d: "3079 JETSTAR AIRWAYS",
    value: "3079",
  },
  {
    d: "3082 KOREAN AIRLINES",
    value: "3082",
  },
  {
    d: "3083 AIR AFRIQUE",
    value: "3083",
  },
  {
    d: "3084 EVA AIRWAYS",
    value: "3084",
  },
  {
    d: "3085 MIDWEST EXPRESS AIRLINES",
    value: "3085",
  },
  {
    d: "3086 CARNIVAL AIRLINES",
    value: "3086",
  },
  {
    d: "3087 METRO AIRLINES",
    value: "3087",
  },
  {
    d: "3088 CROATIA AIRLINES",
    value: "3088",
  },
  {
    d: "3089 TRANSAERO",
    value: "3089",
  },
  {
    d: "3090 UNI AIRWAYS CORPORATION",
    value: "3090",
  },
  {
    d: "3094 ZAMBIA AIRWAYS",
    value: "3094",
  },
  {
    d: "3096 AIR ZIMBABWE",
    value: "3096",
  },
  {
    d: "3097 SPANAIR",
    value: "3097",
  },
  {
    d: "3098 ASIANA AIRLINES",
    value: "3098",
  },
  {
    d: "3099 CATHAY PACIFIC",
    value: "3099",
  },
  {
    d: "3100 MALAYSIAN AIRLINE SYSTEM",
    value: "3100",
  },
  {
    d: "3102 IBERIA",
    value: "3102",
  },
  {
    d: "3103 GARUDA (INDONESIA)",
    value: "3103",
  },
  {
    d: "3106 BRAATHENS S.A.F.E. (NORWAY)",
    value: "3106",
  },
  {
    d: "3110 WINGS AIRWAYS",
    value: "3110",
  },
  {
    d: "3111 BRITISH MIDLAND",
    value: "3111",
  },
  {
    d: "3112 WINDWARD ISLAND",
    value: "3112",
  },
  {
    d: "3115 TOWER AIR",
    value: "3115",
  },
  {
    d: "3117 VIASA",
    value: "3117",
  },
  {
    d: "3118 VALLEY AIRLINES",
    value: "3118",
  },
  {
    d: "3125 TAN",
    value: "3125",
  },
  {
    d: "3126 TALAIR",
    value: "3126",
  },
  {
    d: "3127 TACA INTERNATIONAL",
    value: "3127",
  },
  {
    d: "3129 SURINAM AIRWAYS",
    value: "3129",
  },
  {
    d: "3130 SUNWORLD INTERNATIONAL",
    value: "3130",
  },
  {
    d: "3131 VLM AIRLINES",
    value: "3131",
  },
  {
    d: "3132 FRONTIER AIRLINES",
    value: "3132",
  },
  {
    d: "3133 SUNBELT AIRLINES",
    value: "3133",
  },
  {
    d: "3135 SUDAN AIRWAYS",
    value: "3135",
  },
  {
    d: "3136 QATAR AIRWAYS",
    value: "3136",
  },
  {
    d: "3137 SINGLETON",
    value: "3137",
  },
  {
    d: "3138 SIMMONS AIRLINES",
    value: "3138",
  },
  {
    d: "3143 SCENIC AIRLINES",
    value: "3143",
  },
  {
    d: "3144 VIRGIN ATLANTIC",
    value: "3144",
  },
  {
    d: "3145 SAN JUAN AIRLINES",
    value: "3145",
  },
  {
    d: "3146 LUXAIR",
    value: "3146",
  },
  {
    d: "3148 AIR LITTORAL SA",
    value: "3148",
  },
  {
    d: "3151 AIR ZAIRE",
    value: "3151",
  },
  {
    d: "3154 PRINCEVILLE",
    value: "3154",
  },
  {
    d: "3156 GO FLY LTD",
    value: "3156",
  },
  {
    d: "3159 PBA",
    value: "3159",
  },
  {
    d: "3161 ALL NIPPON AIRWAYS",
    value: "3161",
  },
  {
    d: "3164 NORONTAIR",
    value: "3164",
  },
  {
    d: "3165 NEW YORK HELICOPTER",
    value: "3165",
  },
  {
    d: "3167 AEROCONTINENTE",
    value: "3167",
  },
  {
    d: "3170 MOUNT COOK",
    value: "3170",
  },
  {
    d: "3171 CANADIAN AIRLINES INTERNATIONAL",
    value: "3171",
  },
  {
    d: "3172 NATIONAIR",
    value: "3172",
  },
  {
    d: "3174 JETBLUE AIRWAYS",
    value: "3174",
  },
  {
    d: "3175 MIDDLE EAST AIR",
    value: "3175",
  },
  {
    d: "3176 METROFLIGHT AIRLINES",
    value: "3176",
  },
  {
    d: "3177 AIRTRAN AIRWAYS",
    value: "3177",
  },
  {
    d: "3178 MESA AIR",
    value: "3178",
  },
  {
    d: "3180 WESTJET AIRLINES",
    value: "3180",
  },
  {
    d: "3181 MALEV",
    value: "3181",
  },
  {
    d: "3182 LOT (POLAND)",
    value: "3182",
  },
  {
    d: "3183 OMAN AVIATION SERVICES",
    value: "3183",
  },
  {
    d: "3184 LIAT",
    value: "3184",
  },
  {
    d: "3185 LAV (VENEZUELA)",
    value: "3185",
  },
  {
    d: "3186 LAP (PARAGUAY)",
    value: "3186",
  },
  {
    d: "3187 LACSA (COSTA RICA)",
    value: "3187",
  },
  {
    d: "3188 VIRGIN EXPRESS",
    value: "3188",
  },
  {
    d: "3190 JUGOSLAV AIR",
    value: "3190",
  },
  {
    d: "3191 ISLAND AIRLINES",
    value: "3191",
  },
  {
    d: "3193 INDIAN AIRLINES",
    value: "3193",
  },
  {
    d: "3196 HAWAIIAN AIR",
    value: "3196",
  },
  {
    d: "3197 HAVASU AIRLINES",
    value: "3197",
  },
  {
    d: "3200 GUYANA AIRWAYS",
    value: "3200",
  },
  {
    d: "3203 GOLDEN PACIFIC AIR",
    value: "3203",
  },
  {
    d: "3204 FREEDOM AIR",
    value: "3204",
  },
  {
    d: "3206 CHINA EASTERN AIRLINES",
    value: "3206",
  },
  {
    d: "3211 NORWEGIAN AIR SHUTTLE",
    value: "3211",
  },
  {
    d: "3212 DOMINICANA",
    value: "3212",
  },
  {
    d: "3213 MALMO AVIATION",
    value: "3213",
  },
  {
    d: "3215 DAN AIR SERVICES",
    value: "3215",
  },
  {
    d: "3216 CUMBERLAND AIRLINES",
    value: "3216",
  },
  {
    d: "3217 CSA",
    value: "3217",
  },
  {
    d: "3218 CROWN AIR",
    value: "3218",
  },
  {
    d: "3219 COPA",
    value: "3219",
  },
  {
    d: "3220 COMPANIA FAUCETT",
    value: "3220",
  },
  {
    d: "3221 TRANSPORTES AEROS MILITARES ECUATORIANOS",
    value: "3221",
  },
  {
    d: "3222 COMMAND AIRWAYS",
    value: "3222",
  },
  {
    d: "3223 COMAIR",
    value: "3223",
  },
  {
    d: "3226 SKYWAYS AIR",
    value: "3226",
  },
  {
    d: "3228 CAYMAN AIRWAYS",
    value: "3228",
  },
  {
    d: "3229 SAETA - SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS",
    value: "3229",
  },
  {
    d: "3231 SAHSA - SERVICIO AEREO DE HONDURAS",
    value: "3231",
  },
  {
    d: "3233 CAPITOL AIR",
    value: "3233",
  },
  {
    d: "3234 CARIBBEAN AIRLINES",
    value: "3234",
  },
  {
    d: "3235 BROCKWAY AIR",
    value: "3235",
  },
  {
    d: "3236 AIR ARABIA AIRLINE",
    value: "3236",
  },
  {
    d: "3238 BEMIDJI AIRLINES",
    value: "3238",
  },
  {
    d: "3239 BAR HARBOR AIRLINES",
    value: "3239",
  },
  {
    d: "3240 BAHAMASAIR",
    value: "3240",
  },
  {
    d: "3241 AVIATECA (GUATEMALA)",
    value: "3241",
  },
  {
    d: "3242 AVENSA",
    value: "3242",
  },
  {
    d: "3243 AUSTRIAN AIR SERVICE",
    value: "3243",
  },
  {
    d: "3245 EASYJET AIRLINES",
    value: "3245",
  },
  {
    d: "3246 RYANAIR",
    value: "3246",
  },
  {
    d: "3247 GOL AIRLINES",
    value: "3247",
  },
  {
    d: "3248 TAM AIRLINES",
    value: "3248",
  },
  {
    d: "3251 ALOHA AIRLINES",
    value: "3251",
  },
  {
    d: "3252 ALM",
    value: "3252",
  },
  {
    d: "3253 America West-AMERWEST",
    value: "3253",
  },
  {
    d: "3254 US AIR SHUTTLE",
    value: "3254",
  },
  {
    d: "3256 ALASKA AIRLINES",
    value: "3256",
  },
  {
    d: "3259 AMERICAN TRANS AIR",
    value: "3259",
  },
  {
    d: "3260 SPIRIT AIRLINES",
    value: "3260",
  },
  {
    d: "3261 AIR CHINA",
    value: "3261",
  },
  {
    d: "3262 RENO AIR, INC.",
    value: "3262",
  },
  {
    d: "3263 AERO SERVICO CARABOBO",
    value: "3263",
  },
  {
    d: "3266 AIR SEYCHELLES",
    value: "3266",
  },
  {
    d: "3267 AIR PANAMA",
    value: "3267",
  },
  {
    d: "3280 AIR JAMAICA",
    value: "3280",
  },
  {
    d: "3282 AIR DJIBOUTI",
    value: "3282",
  },
  {
    d: "3284 AERO VIRGIN ISLANDS",
    value: "3284",
  },
  {
    d: "3285 AEROPERU",
    value: "3285",
  },
  {
    d: "3286 AEROLINEAS NICARAGUENSIS",
    value: "3286",
  },
  {
    d: "3287 AERO COACH AVIATION",
    value: "3287",
  },
  {
    d: "3292 CYPRUS AIRWAYS",
    value: "3292",
  },
  {
    d: "3293 ECUATORIANA",
    value: "3293",
  },
  {
    d: "3294 ETHIOPIAN AIRLINES",
    value: "3294",
  },
  {
    d: "3295 KENYA AIRWAYS",
    value: "3295",
  },
  {
    d: "3296 AIR BERLIN",
    value: "3296",
  },
  {
    d: "3297 TAROM ROMANIAN AIR TRANSPORT",
    value: "3297",
  },
  {
    d: "3298 AIR MAURITIUS",
    value: "3298",
  },
  {
    d: "3299 WIDEROE'S FLYVESELSKAP",
    value: "3299",
  },
  {
    d: "3351 AFFILIATED AUTO RENTAL",
    value: "3351",
  },
  {
    d: "3352 AMERICAN INTL RENT-A-CAR",
    value: "3352",
  },
  {
    d: "3353 BROOKS RENT-A-CAR",
    value: "3353",
  },
  {
    d: "3354 ACTION AUTO RENTAL",
    value: "3354",
  },
  {
    d: "3355 SIXT CAR RENTAL",
    value: "3355",
  },
  {
    d: "3357 HERTZ",
    value: "3357",
  },
  {
    d: "3359 PAYLESS CAR RENTAL",
    value: "3359",
  },
  {
    d: "3360 SNAPPY CAR RENTAL",
    value: "3360",
  },
  {
    d: "3361 AIRWAYS RENT-A-CAR",
    value: "3361",
  },
  {
    d: "3362 ALTRA AUTO RENTAL",
    value: "3362",
  },
  {
    d: "3364 AGENCY RENT-A-CAR",
    value: "3364",
  },
  {
    d: "3366 BUDGET RENT-A-CAR",
    value: "3366",
  },
  {
    d: "3368 HOLIDAY RENT-A-CAR",
    value: "3368",
  },
  {
    d: "3370 RENT-A-WRECK",
    value: "3370",
  },
  {
    d: "3374 ACCENT RENT-A-CAR",
    value: "3374",
  },
  {
    d: "3376 AJAX RENT-A-CAR",
    value: "3376",
  },
  {
    d: "3380 TRIANGLE RENT-A-CAR",
    value: "3380",
  },
  {
    d: "3381 EUROP CAR",
    value: "3381",
  },
  {
    d: "3385 TROPICAL RENT-A-CAR",
    value: "3385",
  },
  {
    d: "3386 SHOWCASE RENTAL CARS",
    value: "3386",
  },
  {
    d: "3387 ALAMO RENT-A-CAR",
    value: "3387",
  },
  {
    d: "3388 MERCHANTS RENT-A-CAR",
    value: "3388",
  },
  {
    d: "3389 AVIS RENT-A-CAR",
    value: "3389",
  },
  {
    d: "3390 DOLLAR RENT-A-CAR",
    value: "3390",
  },
  {
    d: "3391 EUROPE BY CAR",
    value: "3391",
  },
  {
    d: "3393 NATIONAL CAR RENTAL",
    value: "3393",
  },
  {
    d: "3394 KEMWELL GROUP RENT-A-CAR",
    value: "3394",
  },
  {
    d: "3395 THRIFTY CAR RENTAL",
    value: "3395",
  },
  {
    d: "3396 TILDEN RENT-A-CAR",
    value: "3396",
  },
  {
    d: "3398 ECONO-CAR RENT-A-CAR",
    value: "3398",
  },
  {
    d: "3400 AUTO HOST CAR RENTALS",
    value: "3400",
  },
  {
    d: "3405 ENTERPRISE RENT-A-CAR",
    value: "3405",
  },
  {
    d: "3409 GENERAL RENT-A-CAR",
    value: "3409",
  },
  {
    d: "3412 A-1 RENT-A-CAR",
    value: "3412",
  },
  {
    d: "3414 GODFREY NATL RENT-A-CAR",
    value: "3414",
  },
  {
    d: "3420 ANSA INTL RENT-A-CAR",
    value: "3420",
  },
  {
    d: "3421 ALLSTATE RENT-A-CAR",
    value: "3421",
  },
  {
    d: "3423 AVCAR RENT-A-CAR",
    value: "3423",
  },
  {
    d: "3425 AUTOMATE RENT-A-CAR",
    value: "3425",
  },
  {
    d: "3427 AVON RENT-A-CAR",
    value: "3427",
  },
  {
    d: "3428 CAREY RENT-A-CAR",
    value: "3428",
  },
  {
    d: "3429 INSURANCE RENT-A-CAR",
    value: "3429",
  },
  {
    d: "3430 MAJOR RENT-A-CAR",
    value: "3430",
  },
  {
    d: "3431 REPLACEMENT RENT-A-CAR",
    value: "3431",
  },
  {
    d: "3432 RESERVE RENT-A-CAR",
    value: "3432",
  },
  {
    d: "3433 UGLY DUCKLING RENT-A-CAR",
    value: "3433",
  },
  {
    d: "3434 USA RENT-A-CAR",
    value: "3434",
  },
  {
    d: "3435 VALUE RENT-A-CAR",
    value: "3435",
  },
  {
    d: "3436 AUTOHANSA RENT-A-CAR",
    value: "3436",
  },
  {
    d: "3437 CITE RENT-A-CAR",
    value: "3437",
  },
  {
    d: "3438 INTERENT RENT-A-CAR",
    value: "3438",
  },
  {
    d: "3439 MILLEVILLE RENT-A-CAR",
    value: "3439",
  },
  {
    d: "3441 ADVANTAGE RENT-A-CAR",
    value: "3441",
  },
  {
    d: "3501 HOLIDAY INNS",
    value: "3501",
  },
  {
    d: "3502 BEST WESTERN HOTELS",
    value: "3502",
  },
  {
    d: "3503 SHERATON",
    value: "3503",
  },
  {
    d: "3504 HILTON HOTELS",
    value: "3504",
  },
  {
    d: "3505 FORTE HOTELS",
    value: "3505",
  },
  {
    d: "3506 GOLDEN TULIP HOTELS",
    value: "3506",
  },
  {
    d: "3507 FRIENDSHIP INNS",
    value: "3507",
  },
  {
    d: "3508 QUALITY INNS",
    value: "3508",
  },
  {
    d: "3509 MARRIOTT",
    value: "3509",
  },
  {
    d: "3510 DAYS INNS",
    value: "3510",
  },
  {
    d: "3511 ARABELLA HOTELS",
    value: "3511",
  },
  {
    d: "3512 INTER-CONTINENTAL HOTELS",
    value: "3512",
  },
  {
    d: "3513 WESTIN",
    value: "3513",
  },
  {
    d: "3514 AMERISUITES",
    value: "3514",
  },
  {
    d: "3515 RODEWAY INNS",
    value: "3515",
  },
  {
    d: "3516 LA QUINTA MOTOR INNS",
    value: "3516",
  },
  {
    d: "3517 AMERICANA HOTELS",
    value: "3517",
  },
  {
    d: "3518 SOL HOTELS",
    value: "3518",
  },
  {
    d: "3519 PULLMAN INTERNATIONAL HOTELS",
    value: "3519",
  },
  {
    d: "3520 MERIDIEN HOTELS",
    value: "3520",
  },
  {
    d: "3521 ROYAL LAHAINA RESORTS",
    value: "3521",
  },
  {
    d: "3522 TOKYO HOTEL",
    value: "3522",
  },
  {
    d: "3523 PENINSULA HOTEL",
    value: "3523",
  },
  {
    d: "3524 WELCOMGROUP HOTELS",
    value: "3524",
  },
  {
    d: "3525 DUNFEY HOTELS",
    value: "3525",
  },
  {
    d: "3526 PRINCE HOTELS",
    value: "3526",
  },
  {
    d: "3527 DOWNTOWNER-PASSPORT HOTEL",
    value: "3527",
  },
  {
    d: "3528 RED LION INNS",
    value: "3528",
  },
  {
    d: "3529 CP HOTELS",
    value: "3529",
  },
  {
    d: "3530 RENAISSANCE HOTELS",
    value: "3530",
  },
  {
    d: "3531 KAUAI COCONUT BEACH RESORT",
    value: "3531",
  },
  {
    d: "3532 ROYAL KONA RESORT",
    value: "3532",
  },
  {
    d: "3533 HOTEL IBIS",
    value: "3533",
  },
  {
    d: "3534 SOUTHERN PACIFIC HOTELS",
    value: "3534",
  },
  {
    d: "3535 HILTON INTERNATIONALS",
    value: "3535",
  },
  {
    d: "3536 AMFAC HOTELS",
    value: "3536",
  },
  {
    d: "3537 ANA HOTEL",
    value: "3537",
  },
  {
    d: "3538 CONCORDE HOTELS",
    value: "3538",
  },
  {
    d: "3539 SUMMERFIELD SUITES HOTELS",
    value: "3539",
  },
  {
    d: "3540 IBEROTEL HOTELS",
    value: "3540",
  },
  {
    d: "3541 HOTEL OKURA",
    value: "3541",
  },
  {
    d: "3542 ROYAL HOTELS",
    value: "3542",
  },
  {
    d: "3543 FOUR SEASONS HOTELS",
    value: "3543",
  },
  {
    d: "3544 CIGA HOTELS",
    value: "3544",
  },
  {
    d: "3545 SHANGRI-LA INTERNATIONAL",
    value: "3545",
  },
  {
    d: "3546 HOTEL SIERRA",
    value: "3546",
  },
  {
    d: "3547 Breakers Resort",
    value: "3547",
  },
  {
    d: "3548 HOTELES MELIA",
    value: "3548",
  },
  {
    d: "3549 AUBERGE DES GOVERNEURS",
    value: "3549",
  },
  {
    d: "3550 REGAL 8 INNS",
    value: "3550",
  },
  {
    d: "3551 MIRAGE HOTEL AND CASINO",
    value: "3551",
  },
  {
    d: "3552 COAST HOTELS",
    value: "3552",
  },
  {
    d: "3553 PARK INNS INTERNATIONAL",
    value: "3553",
  },
  {
    d: "3554 PINEHURST RESORT",
    value: "3554",
  },
  {
    d: "3555 TREASURE ISLAND HOTEL AND CASINO",
    value: "3555",
  },
  {
    d: "3556 BARTON CREEK RESORT",
    value: "3556",
  },
  {
    d: "3557 MANHATTAN EAST SUITE HOTELS",
    value: "3557",
  },
  {
    d: "3558 JOLLY HOTELS",
    value: "3558",
  },
  {
    d: "3559 CANDLEWOOD SUITES",
    value: "3559",
  },
  {
    d: "3560 ALADDIN RESORT AND CASINO",
    value: "3560",
  },
  {
    d: "3561 GOLDEN NUGGET",
    value: "3561",
  },
  {
    d: "3562 COMFORT INNS",
    value: "3562",
  },
  {
    d: "3563 JOURNEY'S END MOTELS",
    value: "3563",
  },
  {
    d: "3564 SAM'S TOWN HOTEL AND CASINO",
    value: "3564",
  },
  {
    d: "3565 RELAX INNS",
    value: "3565",
  },
  {
    d: "3566 GARDEN PLACE HOTEL",
    value: "3566",
  },
  {
    d: "3567 SOHO GRAND HOTEL",
    value: "3567",
  },
  {
    d: "3568 LADBROKE HOTELS",
    value: "3568",
  },
  {
    d: "3569 TRIBECA GRAND HOTEL",
    value: "3569",
  },
  {
    d: "3570 FORUM HOTELS",
    value: "3570",
  },
  {
    d: "3571 GRAND WAILEA RESORT",
    value: "3571",
  },
  {
    d: "3572 MIYAKO HOTELS",
    value: "3572",
  },
  {
    d: "3573 SANDMAN HOTELS",
    value: "3573",
  },
  {
    d: "3574 VENTURE INNS",
    value: "3574",
  },
  {
    d: "3575 VAGABOND HOTELS",
    value: "3575",
  },
  {
    d: "3576 LA QUINTA RESORT",
    value: "3576",
  },
  {
    d: "3577 MANDARIN ORIENTAL HOTEL",
    value: "3577",
  },
  {
    d: "3578 FRANKENMUTH BAVARIAN",
    value: "3578",
  },
  {
    d: "3579 HOTEL MERCURE",
    value: "3579",
  },
  {
    d: "3580 HOTEL DEL CORONADO",
    value: "3580",
  },
  {
    d: "3581 DELTA HOTEL",
    value: "3581",
  },
  {
    d: "3582 CALIFORNIA HOTEL AND CASINO",
    value: "3582",
  },
  {
    d: "3583 RADISSON BLU",
    value: "3583",
  },
  {
    d: "3584 PRINCESS HOTELS INTERNATIONAL",
    value: "3584",
  },
  {
    d: "3585 HUNGAR HOTELS",
    value: "3585",
  },
  {
    d: "3586 SOKOS HOTELS",
    value: "3586",
  },
  {
    d: "3587 DORAL HOTELS",
    value: "3587",
  },
  {
    d: "3588 HELMSLEY HOTELS",
    value: "3588",
  },
  {
    d: "3589 DORAL GOLF RESORT",
    value: "3589",
  },
  {
    d: "3590 FAIRMONT HOTELS",
    value: "3590",
  },
  {
    d: "3591 SONESTA HOTELS",
    value: "3591",
  },
  {
    d: "3592 OMNI HOTELS",
    value: "3592",
  },
  {
    d: "3593 CUNARD HOTELS",
    value: "3593",
  },
  {
    d: "3594 ARIZONA BILTMORE",
    value: "3594",
  },
  {
    d: "3595 HOSPITALITY INNS",
    value: "3595",
  },
  {
    d: "3596 WYNN LAS VEGAS",
    value: "3596",
  },
  {
    d: "3597 RIVERSIDE RESORT HOTEL AND CASINO",
    value: "3597",
  },
  {
    d: "3598 REGENT INTERNATIONAL HOTELS",
    value: "3598",
  },
  {
    d: "3599 PANNONIA HOTELS",
    value: "3599",
  },
  {
    d: "3600 SADDLEBROOK RESORT TAMPA",
    value: "3600",
  },
  {
    d: "3601 TRADEWINDS RESORTS",
    value: "3601",
  },
  {
    d: "3602 HUDSON HOTEL",
    value: "3602",
  },
  {
    d: "3603 NOAH'S HOTELS",
    value: "3603",
  },
  {
    d: "3604 HILTON GARDEN INN",
    value: "3604",
  },
  {
    d: "3605 Jurys Doyle Hotel Group",
    value: "3605",
  },
  {
    d: "3606 Jefferson Hotel",
    value: "3606",
  },
  {
    d: "3607 FONTAINEBLEAU RESORTS",
    value: "3607",
  },
  {
    d: "3608 GAYLORD OPRYLAND",
    value: "3608",
  },
  {
    d: "3609 GAYLORD PALMS",
    value: "3609",
  },
  {
    d: "3610 Gaylord Texan",
    value: "3610",
  },
  {
    d: "3611 C MON INN",
    value: "3611",
  },
  {
    d: "3612 MOEVENPICK HOTELS",
    value: "3612",
  },
  {
    d: "3613 MICROTEL INNS & SUITES",
    value: "3613",
  },
  {
    d: "3614 AMERICINN",
    value: "3614",
  },
  {
    d: "3615 TRAVELODGE",
    value: "3615",
  },
  {
    d: "3616 Hermitage Hotel",
    value: "3616",
  },
  {
    d: "3617 AMERICA'S BEST VALUE INN",
    value: "3617",
  },
  {
    d: "3618 GREAT WOLF",
    value: "3618",
  },
  {
    d: "3619 ALOFT",
    value: "3619",
  },
  {
    d: "3620 BINION'S HORSESHOE CLUB",
    value: "3620",
  },
  {
    d: "3621 EXTENDED STAY",
    value: "3621",
  },
  {
    d: "3622 MERLIN HOTELS",
    value: "3622",
  },
  {
    d: "3623 DORINT HOTELS",
    value: "3623",
  },
  {
    d: "3624 LADY LUCK HOTEL AND CASINO",
    value: "3624",
  },
  {
    d: "3625 HOTEL UNIVERSALE",
    value: "3625",
  },
  {
    d: "3626 STUDIO PLUS",
    value: "3626",
  },
  {
    d: "3627 EXTENDED STAY AMERICA",
    value: "3627",
  },
  {
    d: "3628 EXCALIBUR HOTEL AND CASINO",
    value: "3628",
  },
  {
    d: "3629 DAN HOTELS",
    value: "3629",
  },
  {
    d: "3630 EXTENDED STAY DELUXE",
    value: "3630",
  },
  {
    d: "3631 SLEEP INN",
    value: "3631",
  },
  {
    d: "3632 THE PHOENICIAN",
    value: "3632",
  },
  {
    d: "3633 RANK HOTELS",
    value: "3633",
  },
  {
    d: "3634 SWISSOTEL",
    value: "3634",
  },
  {
    d: "3635 RESO HOTELS",
    value: "3635",
  },
  {
    d: "3636 SAROVA HOTELS",
    value: "3636",
  },
  {
    d: "3637 RAMADA INNS",
    value: "3637",
  },
  {
    d: "3638 HOWARD JOHNSON",
    value: "3638",
  },
  {
    d: "3639 MOUNT CHARLOTTE THISTLE",
    value: "3639",
  },
  {
    d: "3640 HYATT HOTELS",
    value: "3640",
  },
  {
    d: "3641 SOFITEL HOTELS",
    value: "3641",
  },
  {
    d: "3642 NOVOTEL HOTELS",
    value: "3642",
  },
  {
    d: "3643 STEIGENBERGER HOTELS",
    value: "3643",
  },
  {
    d: "3644 ECONO LODGES",
    value: "3644",
  },
  {
    d: "3645 QUEENS MOAT HOUSES",
    value: "3645",
  },
  {
    d: "3646 SWALLOW HOTELS",
    value: "3646",
  },
  {
    d: "3647 HUSA HOTELS",
    value: "3647",
  },
  {
    d: "3648 DE VERE HOTELS",
    value: "3648",
  },
  {
    d: "3649 RADISSON HOTELS",
    value: "3649",
  },
  {
    d: "3650 RED ROOF INNS",
    value: "3650",
  },
  {
    d: "3651 IMPERIAL LONDON HOTEL",
    value: "3651",
  },
  {
    d: "3652 EMBASSY HOTELS",
    value: "3652",
  },
  {
    d: "3653 PENTA HOTELS",
    value: "3653",
  },
  {
    d: "3654 LOEWS HOTELS",
    value: "3654",
  },
  {
    d: "3655 SCANDIC HOTELS",
    value: "3655",
  },
  {
    d: "3656 SARA HOTELS",
    value: "3656",
  },
  {
    d: "3657 OBEROI HOTELS",
    value: "3657",
  },
  {
    d: "3658 NEW OTANI HOTELS",
    value: "3658",
  },
  {
    d: "3659 TAJ HOTELS INTERNATIONAL",
    value: "3659",
  },
  {
    d: "3660 KNIGHTS INNS",
    value: "3660",
  },
  {
    d: "3661 METROPOLE HOTELS",
    value: "3661",
  },
  {
    d: "3662 CIRCUS CIRCUS HOTEL AND CASINO",
    value: "3662",
  },
  {
    d: "3663 HOTELES EL PRESIDENTE",
    value: "3663",
  },
  {
    d: "3664 FLAG INN",
    value: "3664",
  },
  {
    d: "3665 HAMPTON INNS",
    value: "3665",
  },
  {
    d: "3666 STAKIS HOTELS",
    value: "3666",
  },
  {
    d: "3667 LUXOR HOTEL AND CASINO",
    value: "3667",
  },
  {
    d: "3668 MARITIM HOTELS",
    value: "3668",
  },
  {
    d: "3669 ELDORADO HOTEL AND CASINO",
    value: "3669",
  },
  {
    d: "3670 ARCADE HOTELS",
    value: "3670",
  },
  {
    d: "3671 ARCTIA HOTELS",
    value: "3671",
  },
  {
    d: "3672 CAMPANILE HOTELS",
    value: "3672",
  },
  {
    d: "3673 IBUSZ HOTELS",
    value: "3673",
  },
  {
    d: "3674 RANTASIPI HOTELS",
    value: "3674",
  },
  {
    d: "3675 INTERHOTEL CEDOK",
    value: "3675",
  },
  {
    d: "3676 MONTE CARLO HOTEL AND CASINO",
    value: "3676",
  },
  {
    d: "3677 CLIMAT DE FRANCE HOTELS",
    value: "3677",
  },
  {
    d: "3678 CUMULUS HOTELS",
    value: "3678",
  },
  {
    d: "3679 SILVER LEGACY HOTEL AND CASINO",
    value: "3679",
  },
  {
    d: "3680 HOTEIS OTHAN",
    value: "3680",
  },
  {
    d: "3681 ADAMS MARK HOTELS",
    value: "3681",
  },
  {
    d: "3682 SAHARA HOTEL AND CASINO",
    value: "3682",
  },
  {
    d: "3683 BRADBURY SUITES",
    value: "3683",
  },
  {
    d: "3684 BUDGET HOST INNS",
    value: "3684",
  },
  {
    d: "3685 BUDGETEL INNS",
    value: "3685",
  },
  {
    d: "3686 SUSSE CHALET",
    value: "3686",
  },
  {
    d: "3687 CLARION HOTELS",
    value: "3687",
  },
  {
    d: "3688 COMPRI HOTELS",
    value: "3688",
  },
  {
    d: "3689 CONSORT HOTELS",
    value: "3689",
  },
  {
    d: "3690 COURTYARD BY MARRIOTT",
    value: "3690",
  },
  {
    d: "3691 DILLON INNS",
    value: "3691",
  },
  {
    d: "3692 DOUBLETREE HOTELS",
    value: "3692",
  },
  {
    d: "3693 DRURY INNS",
    value: "3693",
  },
  {
    d: "3694 ECONOMY INNS OF AMERICA",
    value: "3694",
  },
  {
    d: "3695 EMBASSY SUITES",
    value: "3695",
  },
  {
    d: "3696 EXEL INNS",
    value: "3696",
  },
  {
    d: "3697 FAIRFIELD HOTELS",
    value: "3697",
  },
  {
    d: "3698 HARLEY HOTELS",
    value: "3698",
  },
  {
    d: "3699 MIDWAY MOTOR LODGE",
    value: "3699",
  },
  {
    d: "3700 MOTEL 6",
    value: "3700",
  },
  {
    d: "3701 LA MANSION DEL RIO",
    value: "3701",
  },
  {
    d: "3702 THE REGISTRY HOTELS",
    value: "3702",
  },
  {
    d: "3703 RESIDENCE INNS",
    value: "3703",
  },
  {
    d: "3704 ROYCE HOTELS",
    value: "3704",
  },
  {
    d: "3705 SANDMAN INNS",
    value: "3705",
  },
  {
    d: "3706 SHILO INNS",
    value: "3706",
  },
  {
    d: "3707 SHONEY'S INNS",
    value: "3707",
  },
  {
    d: "3708 VIRGIN RIVER HOTEL AND CASINO",
    value: "3708",
  },
  {
    d: "3709 SUPER 8 MOTELS",
    value: "3709",
  },
  {
    d: "3710 THE RITZ-CARLTON",
    value: "3710",
  },
  {
    d: "3711 FLAG INNS (AUSTRALIA)",
    value: "3711",
  },
  {
    d: "3712 BUFFALO BILL'S HOTEL AND CASINO",
    value: "3712",
  },
  {
    d: "3713 QUALITY PACIFIC HOTEL",
    value: "3713",
  },
  {
    d: "3714 FOUR SEASONS HOTEL (AUSTRALIA)",
    value: "3714",
  },
  {
    d: "3715 FAIRFIELD INN",
    value: "3715",
  },
  {
    d: "3716 CARLTON HOTELS",
    value: "3716",
  },
  {
    d: "3717 CITY LODGE HOTELS",
    value: "3717",
  },
  {
    d: "3718 KAROS HOTELS",
    value: "3718",
  },
  {
    d: "3719 PROTEA HOTELS",
    value: "3719",
  },
  {
    d: "3720 SOUTHERN SUN HOTELS",
    value: "3720",
  },
  {
    d: "3721 HILTON CONRAD",
    value: "3721",
  },
  {
    d: "3722 WYNDHAM",
    value: "3722",
  },
  {
    d: "3723 RICA HOTELS",
    value: "3723",
  },
  {
    d: "3724 INTER NOR HOTELS",
    value: "3724",
  },
  {
    d: "3725 SEA PINES RESORT",
    value: "3725",
  },
  {
    d: "3726 RIO SUITES",
    value: "3726",
  },
  {
    d: "3727 BROADMOOR HOTEL",
    value: "3727",
  },
  {
    d: "3728 BALLY'S HOTEL AND CASINO",
    value: "3728",
  },
  {
    d: "3729 JOHN ASCUAGA'S NUGGET",
    value: "3729",
  },
  {
    d: "3730 MGM GRAND HOTEL",
    value: "3730",
  },
  {
    d: "3731 HARRAH'S HOTELS AND CASINOS",
    value: "3731",
  },
  {
    d: "3732 OPRYLAND HOTEL",
    value: "3732",
  },
  {
    d: "3733 Boca Raton Resort",
    value: "3733",
  },
  {
    d: "3734 HARVEY/BRISTOL HOTELS",
    value: "3734",
  },
  {
    d: "3735 MASTERS ECONOMY INNS",
    value: "3735",
  },
  {
    d: "3736 COLORADO BELLE/EDGEWATER RESORT",
    value: "3736",
  },
  {
    d: "3737 RIVIERA HOTEL AND CASINO",
    value: "3737",
  },
  {
    d: "3738 TROPICANA RESORT & CASINO",
    value: "3738",
  },
  {
    d: "3739 WOODSIDE HOTELS & RESORTS",
    value: "3739",
  },
  {
    d: "3740 TOWNEPLACE SUITES",
    value: "3740",
  },
  {
    d: "3741 MILLENNIUM HOTELS",
    value: "3741",
  },
  {
    d: "3742 CLUB MED",
    value: "3742",
  },
  {
    d: "3743 BILTMORE HOTEL & SUITES",
    value: "3743",
  },
  {
    d: "3744 CAREFREE RESORTS",
    value: "3744",
  },
  {
    d: "3745 ST. REGIS HOTEL",
    value: "3745",
  },
  {
    d: "3746 THE ELIOT HOTEL",
    value: "3746",
  },
  {
    d: "3747 CLUBCORP/CLUBRESORTS",
    value: "3747",
  },
  {
    d: "3748 WELLESLEY INNS",
    value: "3748",
  },
  {
    d: "3749 THE BEVERLY HILLS HOTEL",
    value: "3749",
  },
  {
    d: "3750 CROWNE PLAZA HOTELS",
    value: "3750",
  },
  {
    d: "3751 HOMEWOOD SUITES",
    value: "3751",
  },
  {
    d: "3752 PEABODY HOTELS",
    value: "3752",
  },
  {
    d: "3753 GREENBRIAR RESORTS",
    value: "3753",
  },
  {
    d: "3754 AMELIA ISLAND PLANTATION",
    value: "3754",
  },
  {
    d: "3755 THE HOMESTEAD",
    value: "3755",
  },
  {
    d: "3757 CANYON RANCH",
    value: "3757",
  },
  {
    d: "3758 KAHALA MANDARIN ORIENTAL HOTEL",
    value: "3758",
  },
  {
    d: "3759 THE ORCHID AT MAUNA LANI",
    value: "3759",
  },
  {
    d: "3760 HALEKULANI HOTEL/WAIKIKI PARC",
    value: "3760",
  },
  {
    d: "3761 PRIMADONNA HOTEL AND CASINO",
    value: "3761",
  },
  {
    d: "3762 WHISKEY PETE'S HOTEL AND CASINO",
    value: "3762",
  },
  {
    d: "3763 CHATEAU ELAN WINERY AND RESORT",
    value: "3763",
  },
  {
    d: "3764 BEAU RIVAGE HOTEL AND CASINO",
    value: "3764",
  },
  {
    d: "3765 BELLAGIO",
    value: "3765",
  },
  {
    d: "3766 FREMONT HOTEL AND CASINO",
    value: "3766",
  },
  {
    d: "3767 MAIN STREET STATION HOTEL AND CASINO",
    value: "3767",
  },
  {
    d: "3768 SILVER STAR HOTEL AND CASINO",
    value: "3768",
  },
  {
    d: "3769 STRATOSPHERE HOTEL AND CASINO",
    value: "3769",
  },
  {
    d: "3770 SPRINGHILL SUITES",
    value: "3770",
  },
  {
    d: "3771 CAESAR'S HOTEL AND CASINO",
    value: "3771",
  },
  {
    d: "3772 NEMACOLIN WOODLANDS",
    value: "3772",
  },
  {
    d: "3773 THE VENETIAN RESORT HOTEL CASINO",
    value: "3773",
  },
  {
    d: "3774 NEW YORK-NEW YORK HOTEL AND CASINO",
    value: "3774",
  },
  {
    d: "3775 SANDS RESORT",
    value: "3775",
  },
  {
    d: "3776 NEVELE GRAND RESORT AND COUNTRY CLUB",
    value: "3776",
  },
  {
    d: "3777 MANDALAY BAY RESORT",
    value: "3777",
  },
  {
    d: "3778 FOUR POINTS HOTELS",
    value: "3778",
  },
  {
    d: "3779 W HOTELS",
    value: "3779",
  },
  {
    d: "3780 DISNEY RESORTS",
    value: "3780",
  },
  {
    d: "3781 PATRICIA GRAND RESORT HOTELS",
    value: "3781",
  },
  {
    d: "3782 ROSEN HOTELS AND RESORTS",
    value: "3782",
  },
  {
    d: "3783 TOWN AND COUNTRY RESORT & CONVENTION CENTER",
    value: "3783",
  },
  {
    d: "3784 FIRST HOSPITALITY HOTELS",
    value: "3784",
  },
  {
    d: "3785 OUTRIGGER HOTELS AND RESORTS",
    value: "3785",
  },
  {
    d: "3786 OHANA HOTELS OF HAWAII",
    value: "3786",
  },
  {
    d: "3787 CARIBE ROYALE RESORTS",
    value: "3787",
  },
  {
    d: "3788 ALA MOANA HOTEL",
    value: "3788",
  },
  {
    d: "3789 SMUGGLER'S NOTCH RESORT",
    value: "3789",
  },
  {
    d: "3790 RAFFLES HOTELS",
    value: "3790",
  },
  {
    d: "3791 STAYBRIDGE SUITES",
    value: "3791",
  },
  {
    d: "3792 CLARIDGE CASINO HOTEL",
    value: "3792",
  },
  {
    d: "3793 FLAMINGO HOTELS",
    value: "3793",
  },
  {
    d: "3794 GRAND CASINO HOTELS",
    value: "3794",
  },
  {
    d: "3795 PARIS LAS VEGAS HOTEL",
    value: "3795",
  },
  {
    d: "3796 PEPPERMILL HOTEL CASINO",
    value: "3796",
  },
  {
    d: "3797 ATLANTIC CITY HILTON RESORTS",
    value: "3797",
  },
  {
    d: "3798 EMBASSY VACATION RESORT",
    value: "3798",
  },
  {
    d: "3799 HALE KOA HOTEL",
    value: "3799",
  },
  {
    d: "3800 HOMESTEAD SUITES",
    value: "3800",
  },
  {
    d: "3801 WILDERNESS HOTEL AND RESORT",
    value: "3801",
  },
  {
    d: "3802 THE PALACE HOTEL",
    value: "3802",
  },
  {
    d: "3803 The Wigwam Golf Resort and Spa",
    value: "3803",
  },
  {
    d: "3804 The Diplomat Country Club and Spa",
    value: "3804",
  },
  {
    d: "3805 The Atlantic",
    value: "3805",
  },
  {
    d: "3806 Princeville Resort",
    value: "3806",
  },
  {
    d: "3807 ELEMENT",
    value: "3807",
  },
  {
    d: "3808 LXR",
    value: "3808",
  },
  {
    d: "3809 Settle Inn",
    value: "3809",
  },
  {
    d: "3810 La Costa Resort",
    value: "3810",
  },
  {
    d: "3811 PREMIER INN",
    value: "3811",
  },
  {
    d: "3812 HYATT PLACE",
    value: "3812",
  },
  {
    d: "3813 HOTEL INDIGO",
    value: "3813",
  },
  {
    d: "3814 THE ROOSEVELT HOTEL NY",
    value: "3814",
  },
  {
    d: "3815 NICKELODEON FAMILY SUITES BY HOLIDAY INN",
    value: "3815",
  },
  {
    d: "3816 HOME2SUITES",
    value: "3816",
  },
  {
    d: "3817 Affinia",
    value: "3817",
  },
  {
    d: "3818 MAINSTAY SUITES",
    value: "3818",
  },
  {
    d: "3819 OXFORD SUITES",
    value: "3819",
  },
  {
    d: "3820 JUMEIRAH ESSEX HOUSE",
    value: "3820",
  },
  {
    d: "3821 CARIBE ROYALE",
    value: "3821",
  },
  {
    d: "3822 CROSSLAND",
    value: "3822",
  },
  {
    d: "3823 GRAND SIERRA RESORT",
    value: "3823",
  },
  {
    d: "3824 ARIA",
    value: "3824",
  },
  {
    d: "3825 VDARA",
    value: "3825",
  },
  {
    d: "3826 AUTOGRAPH",
    value: "3826",
  },
  {
    d: "3827 GALT HOUSE",
    value: "3827",
  },
  {
    d: "3828 COSMOPOLITAN OF LAS VEGAS",
    value: "3828",
  },
  {
    d: "3829 COUNTRY INN BY CARLSON",
    value: "3829",
  },
  {
    d: "3830 PARK PLAZA HOTEL",
    value: "3830",
  },
  {
    d: "3831 WALDORF",
    value: "3831",
  },
  {
    d: "3832 CURIO HOTELS",
    value: "3832",
  },
  {
    d: "3833 CANOPY",
    value: "3833",
  },
  {
    d: "4011 Railroads",
    value: "4011",
  },
  {
    d: "4111 Local and Suburban Commuter Passenger",
    value: "4111",
  },
  {
    d: "4112 Passenger Railways",
    value: "4112",
  },
  {
    d: "4119 Ambulance Services",
    value: "4119",
  },
  {
    d: "4121 Taxicabs and Limousines",
    value: "4121",
  },
  {
    d: "4131 Bus Lines",
    value: "4131",
  },
  {
    d: "4214 Motor Freight Carriers and Trucking",
    value: "4214",
  },
  {
    d: "4215 Courier Services - Air and Ground, and Freight Forwarders",
    value: "4215",
  },
  {
    d: "4225 Direct Marketing, Inbound Teleservices Merchant",
    value: "4225",
  },
  {
    d: "4411 Steamship and Cruise Lines",
    value: "4411",
  },
  {
    d: "4457 Boat Rentals and Leasing",
    value: "4457",
  },
  {
    d: "4468 Marinas, Marine Service, and Supplies",
    value: "4468",
  },
  {
    d: "4511 Airlines and Air Carriers",
    value: "4511",
  },
  {
    d: "4582 Airports, Flying Fields, and Airport Terminals",
    value: "4582",
  },
  {
    d: "4722 Travel Agencies and Tour Operators",
    value: "4722",
  },
  {
    d: "4723 Package Tour Operators - Germany Only",
    value: "4723",
  },
  {
    d: "4784 Tolls and Bridge Fees",
    value: "4784",
  },
  {
    d: "4789 Transportation Services",
    value: "4789",
  },
  {
    d: "4812 Telecommunication Equipment and Telephone Sales",
    value: "4812",
  },
  {
    d: "4813 Key-entry Telecom Merchant providing single local and longdistance phone calls using a central access number in a non-face-to-face environment using key entry",
    value: "4813",
  },
  {
    d: "4814 Telecommunication Services, including Local and Long Distance Calls,",
    value: "4814",
  },
  {
    d: "4816 Computer Network/Information Services",
    value: "4816",
  },
  {
    d: "4821 Telegraph Services",
    value: "4821",
  },
  {
    d: "4829 Wire Transfer Money Orders (WTMOs)",
    value: "4829",
  },
  {
    d: "4899 Cable, Satellite, and Other Pay Television/Radio/Streaming Services",
    value: "4899",
  },
  {
    d: "4900 Utilities - Electric, Gas, Water, Sanitary",
    value: "4900",
  },
  {
    d: "5013 Motor Vehicle Supplies and New Parts (B2B)",
    value: "5013",
  },
  {
    d: "5021 Office and Commercial Furniture (B2B)",
    value: "5021",
  },
  {
    d: "5039 Construction Materials",
    value: "5039",
  },
  {
    d: "5044 Photographic, Photocopy, Microfilm Equipment and Supplies (B2B)",
    value: "5044",
  },
  {
    d: "5045 Computers and Computer Peripheral Equipment and Software (B2B)",
    value: "5045",
  },
  {
    d: "5046 Commercial Equipment",
    value: "5046",
  },
  {
    d: "5047 Medical, Dental, Ophthalmic and Hospital",
    value: "5047",
  },
  {
    d: "5051 Metal Service Centers and Offices (B2B)",
    value: "5051",
  },
  {
    d: "5065 Electrical Parts and Equipment (B2B)",
    value: "5065",
  },
  {
    d: "5072 Hardware, Equipment and Supplies (B2B)",
    value: "5072",
  },
  {
    d: "5074 Plumbing and Heating Equipment and Supplies (B2B)",
    value: "5074",
  },
  {
    d: "5085 Industrial Supplies (Not Elsewhere Classified) (B2B)",
    value: "5085",
  },
  {
    d: "5094 Precious Stones and Metals, Watches and Jewelry (B2B)",
    value: "5094",
  },
  {
    d: "5099 Durable Goods (Not Elsewhere Classified) (B2B)",
    value: "5099",
  },
  {
    d: "5111 Stationery, Office Supplies, Printing and Writing Paper (B2B)",
    value: "5111",
  },
  {
    d: "5122 Drugs, Drug Proprietaries, and Druggist Sundries (B2B)",
    value: "5122",
  },
  {
    d: "5131 Piece Goods, Notions, and Other Dry Goods (B2B)",
    value: "5131",
  },
  {
    d: "5137 Men's, Women's, and Children's Uniforms and Commercial Clothing (B2B)",
    value: "5137",
  },
  {
    d: "5139 Commercial Footwear (B2B)",
    value: "5139",
  },
  {
    d: "5169 Chemicals and Allied Products (Not Elsewhere Classified) (B2B)",
    value: "5169",
  },
  {
    d: "5172 Petroleum and Petroleum Products (B2B)",
    value: "5172",
  },
  {
    d: "5192 Books, Periodicals and Newspapers (B2B)",
    value: "5192",
  },
  {
    d: "5193 Florists Supplies, Nursery Stock and Flowers (B2B)",
    value: "5193",
  },
  {
    d: "5198 Paints, Varnishes and Supplies (B2B)",
    value: "5198",
  },
  {
    d: "5199 Nondurable Goods (Not Elsewhere Classified) (B2B)",
    value: "5199",
  },
  {
    d: "5200 Home Supply Warehouse Stores",
    value: "5200",
  },
  {
    d: "5211 Lumber and Building Materials Stores",
    value: "5211",
  },
  {
    d: "5231 Glass, Paint, and Wallpaper Stores",
    value: "5231",
  },
  {
    d: "5251 Hardware Stores",
    value: "5251",
  },
  {
    d: "5261 Nurseries and Lawn and Garden Supply Stores",
    value: "5261",
  },
  {
    d: "5262 Marketplaces",
    value: "5262",
  },
  {
    d: "5271 Mobile Home Dealers",
    value: "5271",
  },
  {
    d: "5300 Wholesale Clubs",
    value: "5300",
  },
  {
    d: "5309 Duty Free Stores",
    value: "5309",
  },
  {
    d: "5310 Discount Stores",
    value: "5310",
  },
  {
    d: "5311 Department Stores",
    value: "5311",
  },
  {
    d: "5331 Variety Stores",
    value: "5331",
  },
  {
    d: "5399 Miscellaneous General Merchandise",
    value: "5399",
  },
  {
    d: "5411 Grocery Stores and Supermarkets",
    value: "5411",
  },
  {
    d: "5422 Freezer and Locker Meat Provisioners",
    value: "5422",
  },
  {
    d: "5441 Candy, Nut, and Confectionery Stores",
    value: "5441",
  },
  {
    d: "5451 Dairy Products Stores",
    value: "5451",
  },
  {
    d: "5462 Bakeries",
    value: "5462",
  },
  {
    d: "5499 Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
    value: "5499",
  },
  {
    d: "5511 Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing",
    value: "5511",
  },
  {
    d: "5521 Car and Truck Dealers (Used Only)",
    value: "5521",
  },
  {
    d: "5531 Auto Store",
    value: "5531",
  },
  {
    d: "5532 Automotive Tire Stores",
    value: "5532",
  },
  {
    d: "5533 Automotive Parts and Accessories Stores",
    value: "5533",
  },
  {
    d: "5541 Service Stations (With or Without Ancillary Services)",
    value: "5541",
  },
  {
    d: "5542 Automated Fuel Dispensers",
    value: "5542",
  },
  {
    d: "5551 Boat Dealers",
    value: "5551",
  },
  {
    d: "5561 Camper, Recreational and Utility Trailer Dealers",
    value: "5561",
  },
  {
    d: "5571 Motorcycle Shops and Dealers",
    value: "5571",
  },
  {
    d: "5592 Motor Home Dealers",
    value: "5592",
  },
  {
    d: "5598 Snowmobile Dealers",
    value: "5598",
  },
  {
    d: "5599 Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers (Not Elsewhere Classified)",
    value: "5599",
  },
  {
    d: "5611 Men's and Boys' Clothing and Accessories Stores",
    value: "5611",
  },
  {
    d: "5621 Women's Ready-To-Wear Stores",
    value: "5621",
  },
  {
    d: "5631 Womens Accessory and Specialty Shops",
    value: "5631",
  },
  {
    d: "5641 Children's and Infants' Wear Stores",
    value: "5641",
  },
  {
    d: "5651 Family Clothing Stores",
    value: "5651",
  },
  {
    d: "5655 Sports and Riding Apparel Stores",
    value: "5655",
  },
  {
    d: "5661 Shoe Stores",
    value: "5661",
  },
  {
    d: "5681 Furriers and Fur Shops",
    value: "5681",
  },
  {
    d: "5691 Men's and Women's Clothing Stores",
    value: "5691",
  },
  {
    d: "5697 Tailors, Seamstresses, Mending, and Alterations",
    value: "5697",
  },
  {
    d: "5698 Wig and Toupee Stores",
    value: "5698",
  },
  {
    d: "5699 Miscellaneous Apparel and Accessory Shops",
    value: "5699",
  },
  {
    d: "5712 Furniture, Home Furnishings and Equipment Stores, Except Appliances",
    value: "5712",
  },
  {
    d: "5713 Floor Covering Stores",
    value: "5713",
  },
  {
    d: "5714 Drapery, Window Covering, and Upholstery Stores",
    value: "5714",
  },
  {
    d: "5718 Fireplace, Fireplace Screens and Accessories Stores",
    value: "5718",
  },
  {
    d: "5719 Miscellaneous Home Furnishing Specialty Stores",
    value: "5719",
  },
  {
    d: "5722 Household Appliance Stores",
    value: "5722",
  },
  {
    d: "5732 Electronics Sales",
    value: "5732",
  },
  {
    d: "5733 Music Stores - Musical Instruments, Pianos, and Sheet Music",
    value: "5733",
  },
  {
    d: "5734 Computer Software Stores",
    value: "5734",
  },
  {
    d: "5735 Record Stores",
    value: "5735",
  },
  {
    d: "5811 Caterers",
    value: "5811",
  },
  {
    d: "5812 Eating Places and Restaurants",
    value: "5812",
  },
  {
    d: "5813 Drinking Places (Alcoholic",
    value: "5813",
  },
  {
    d: "5814 Fast Food Restaurants",
    value: "5814",
  },
  {
    d: "5815 Digital Goods Media - Books, Movies, Music",
    value: "5815",
  },
  {
    d: "5816 Digital Goods - Games",
    value: "5816",
  },
  {
    d: "5817 Digital Goods - Applications (Excludes Games)",
    value: "5817",
  },
  {
    d: "5818 Digital Goods - Large Digital Goods Merchant",
    value: "5818",
  },
  {
    d: "5912 Drug Stores and Pharmacies",
    value: "5912",
  },
  {
    d: "5921 Package Stores - Beer, Wine, and Liquor",
    value: "5921",
  },
  {
    d: "5931 Used Merchandise and Secondhand Stores",
    value: "5931",
  },
  {
    d: "5932 Antique Shops - Sales, Repairs, and Restoration Services",
    value: "5932",
  },
  {
    d: "5933 Pawn Shops",
    value: "5933",
  },
  {
    d: "5935 Wrecking and Salvage Yards",
    value: "5935",
  },
  {
    d: "5937 Antique Reproductions",
    value: "5937",
  },
  {
    d: "5940 Bicycle Shops - Sales and Service",
    value: "5940",
  },
  {
    d: "5941 Sporting Goods Stores",
    value: "5941",
  },
  {
    d: "5942 Book Stores",
    value: "5942",
  },
  {
    d: "5943 Stationery Stores, Office and School Supply Stores",
    value: "5943",
  },
  {
    d: "5944 Jewelry Stores, Watches, Clocks, and Silverware Stores",
    value: "5944",
  },
  {
    d: "5945 Hobby, Toy and Game Shops",
    value: "5945",
  },
  {
    d: "5946 Camera and Photographic Supply Stores",
    value: "5946",
  },
  {
    d: "5947 Gift, Card, Novelty and Souvenir Shops",
    value: "5947",
  },
  {
    d: "5948 Luggage and Leather Goods Stores",
    value: "5948",
  },
  {
    d: "5949 Sewing, Needlework, Fabric and Piece Goods Stores",
    value: "5949",
  },
  {
    d: "5950 Glassware/Crystal Stores",
    value: "5950",
  },
  {
    d: "5960 Direct Marketing - Insurance Services",
    value: "5960",
  },
  {
    d: "5962 Direct Marketing - Travel-Related Arrangement Services",
    value: "5962",
  },
  {
    d: "5963 Door-To-Door Sales",
    value: "5963",
  },
  {
    d: "5964 Direct Marketing - Catalog Merchant",
    value: "5964",
  },
  {
    d: "5965 Direct Marketing - Combination Catalog and Retail Merchant",
    value: "5965",
  },
  {
    d: "5966 Direct Marketing - Outbound Telemarketing Merchant",
    value: "5966",
  },
  {
    d: "5967 Direct Marketing - Inbound Teleservices Merchant",
    value: "5967",
  },
  {
    d: "5968 Direct Marketing - Continuity/Subscription Merchant",
    value: "5968",
  },
  {
    d: "5969 Direct Marketing - Other Direct Marketers (Not Elsewhere Classified)",
    value: "5969",
  },
  {
    d: "5970 Artist's Supply and Craft Shops",
    value: "5970",
  },
  {
    d: "5971 Art Dealers and Galleries",
    value: "5971",
  },
  {
    d: "5972 Stamp and Coin Stores",
    value: "5972",
  },
  {
    d: "5973 Religious Goods Stores",
    value: "5973",
  },
  {
    d: "5975 Hearing Aids - Sales, Service, and Supply",
    value: "5975",
  },
  {
    d: "5976 Orthopedic Goods - Prosthetic Devices",
    value: "5976",
  },
  {
    d: "5977 Cosmetic Stores",
    value: "5977",
  },
  {
    d: "5978 Typewriter Stores - Sales, Rentals, and Service",
    value: "5978",
  },
  {
    d: "5983 Fuel Dealers - Fuel Oil, Wood, Coal, and Liquefied Petroleum",
    value: "5983",
  },
  {
    d: "5992 Florists",
    value: "5992",
  },
  {
    d: "5993 Cigar Stores and Stands",
    value: "5993",
  },
  {
    d: "5994 News Dealers and Newsstands",
    value: "5994",
  },
  {
    d: "5995 Pet Shops, Pet Foods and Supplies Stores",
    value: "5995",
  },
  {
    d: "5996 Swimming Pools - Sales and Service",
    value: "5996",
  },
  {
    d: "5997 Electric Razor Stores - Sales and Service",
    value: "5997",
  },
  {
    d: "5998 Tent and Awning Shops",
    value: "5998",
  },
  {
    d: "5999 Miscellaneous and Specialty Retail Shops",
    value: "5999",
  },
  {
    d: "6010 Financial Institutions - Manual Cash Disbursements",
    value: "6010",
  },
  {
    d: "6011 Financial Institutions - Automated Cash Disbursements",
    value: "6011",
  },
  {
    d: "6012 Financial Institution - Merchandise and Services",
    value: "6012",
  },
  {
    d: "6050 Quasi Cash-Customer Financial Institution",
    value: "6050",
  },
  {
    d: "6051 Non-Financial Institutions - Foreign Currency, Money Orders (Not Wire Transfer), Stored Value Card/Load, and Travelers Cheques",
    value: "6051",
  },
  {
    d: "6211 Security Brokers/Dealers",
    value: "6211",
  },
  {
    d: "6300 Insurance Sales, Underwriting, and Premiums",
    value: "6300",
  },
  {
    d: "6513 Real Estate Agents and Managers - Rentals",
    value: "6513",
  },
  {
    d: "6532 Payment Transaction-Customer Financial Institution",
    value: "6532",
  },
  {
    d: "6533 Payment Transaction-Merchant",
    value: "6533",
  },
  {
    d: "6536 MoneySend Intracountry",
    value: "6536",
  },
  {
    d: "6537 MoneySend Intercountry",
    value: "6537",
  },
  {
    d: "6538 MoneySend Funding",
    value: "6538",
  },
  {
    d: "6540 Non-Financial Institutions: Stored Value Card Purchase / Load",
    value: "6540",
  },
  {
    d: "7011 LODGING - HOTELS, MOTELS, RESORTS,",
    value: "7011",
  },
  {
    d: "7012 Timeshares",
    value: "7012",
  },
  {
    d: "7032 Sporting and Recreational Camps",
    value: "7032",
  },
  {
    d: "7033 Trailer Parks and Campgrounds",
    value: "7033",
  },
  {
    d: "7210 Laundry, Cleaning, and Garment Services",
    value: "7210",
  },
  {
    d: "7211 Laundries - Family and Commercial",
    value: "7211",
  },
  {
    d: "7216 Dry Cleaners",
    value: "7216",
  },
  {
    d: "7217 Carpet and Upholstery Cleaning",
    value: "7217",
  },
  {
    d: "7221 Photographic Studios",
    value: "7221",
  },
  {
    d: "7230 Beauty and Barber Shops",
    value: "7230",
  },
  {
    d: "7251 Shoe Repair Shops, Shoe Shine Parlors, and Hat Cleaning Shops",
    value: "7251",
  },
  {
    d: "7261 Funeral Services and Crematories",
    value: "7261",
  },
  {
    d: "7273 Dating Services",
    value: "7273",
  },
  {
    d: "7276 Tax Preparation Services",
    value: "7276",
  },
  {
    d: "7277 Counseling Services - Debt, Marriage, and Personal",
    value: "7277",
  },
  {
    d: "7278 Buying and Shopping Services and Clubs",
    value: "7278",
  },
  {
    d: "7296 Clothing Rental - Costumes, Uniforms, Formal Wear",
    value: "7296",
  },
  {
    d: "7297 Massage Parlors",
    value: "7297",
  },
  {
    d: "7298 Health and Beauty Spas",
    value: "7298",
  },
  {
    d: "7299 Miscellaneous Personal Services (Not Elsewhere Classified)",
    value: "7299",
  },
  {
    d: "7311 Advertising Services",
    value: "7311",
  },
  {
    d: "7321 Consumer Credit Reporting Agencies",
    value: "7321",
  },
  {
    d: "7333 Commercial Photography, Art, and Graphics",
    value: "7333",
  },
  {
    d: "7338 Quick Copy, Reproduction, and Blueprinting Services",
    value: "7338",
  },
  {
    d: "7339 Stenographic and Secretarial Support",
    value: "7339",
  },
  {
    d: "7342 Exterminating and Disinfecting Services",
    value: "7342",
  },
  {
    d: "7349 Cleaning, Maintenance, and Janitorial Services",
    value: "7349",
  },
  {
    d: "7361 Employment Agencies and Temporary Help Services",
    value: "7361",
  },
  {
    d: "7372 Computer Programming, Data Processing, and Integrated Systems Design Services",
    value: "7372",
  },
  {
    d: "7375 Information Retrieval Services (B2B)",
    value: "7375",
  },
  {
    d: "7379 Computer Maintenance, Repair and Services (Not Elsewhere Classified) (B2B)",
    value: "7379",
  },
  {
    d: "7392 Management, Consulting, and Public Relations Services",
    value: "7392",
  },
  {
    d: "7393 Detective Agencies, Protective Agencies, and Security Services, including Armored Cars, and Guard Dogs",
    value: "7393",
  },
  {
    d: "7394 Equipment, Tool, Furniture, and Appliance Rental and Leasing",
    value: "7394",
  },
  {
    d: "7395 Photofinishing Laboratories and Photo Developing",
    value: "7395",
  },
  {
    d: "7399 Business Service (Not Elsewhere Classified) (B2B)",
    value: "7399",
  },
  {
    d: "7512 Automobile Rental Agency",
    value: "7512",
  },
  {
    d: "7513 Truck and Utility Trailer Rentals",
    value: "7513",
  },
  {
    d: "7519 Motor Home and Recreational Vehicle Rentals",
    value: "7519",
  },
  {
    d: "7523 Parking Lots, Parking Meters and Garages",
    value: "7523",
  },
  {
    d: "7531 Automotive Body Repair Shops",
    value: "7531",
  },
  {
    d: "7534 Tire Retreading and Repair Shops",
    value: "7534",
  },
  {
    d: "7535 Automotive Paint Shops",
    value: "7535",
  },
  {
    d: "7538 Automotive Service Shops",
    value: "7538",
  },
  {
    d: "7542 Car Washes",
    value: "7542",
  },
  {
    d: "7549 Towing Services",
    value: "7549",
  },
  {
    d: "7622 Electronics Repair Shops",
    value: "7622",
  },
  {
    d: "7623 Air Conditioning and Refrigeration Repair Shops",
    value: "7623",
  },
  {
    d: "7629 Electrical and Small Appliance Repair Shops",
    value: "7629",
  },
  {
    d: "7631 Watch, Clock and Jewelry Repair",
    value: "7631",
  },
  {
    d: "7641 Furniture - Reupholstery, Repair, and Refinishing",
    value: "7641",
  },
  {
    d: "7692 Welding Services",
    value: "7692",
  },
  {
    d: "7699 Miscellaneous Repair Shops and Related Services",
    value: "7699",
  },
  {
    d: "7800 Government-Owned Lotteries (US Region)",
    value: "7800",
  },
  {
    d: "7801 Government-Licensed On-Line Casinos (On-Line Gambling)",
    value: "7801",
  },
  {
    d: "7802 Government-Licensed Horse/Dog Racing",
    value: "7802",
  },
  {
    d: "7829 Motion Picture and Video Tape Production and Distribution (B2B)",
    value: "7829",
  },
  {
    d: "7832 Motion Picture Theaters",
    value: "7832",
  },
  {
    d: "7841 DVD/Video Tape Rental Stores",
    value: "7841",
  },
  {
    d: "7911 Dance Halls, Studios and Schools",
    value: "7911",
  },
  {
    d: "7922 Theatrical Producers (Eexcept Motion Pictures) and Ticket Agencies",
    value: "7922",
  },
  {
    d: "7929 Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)",
    value: "7929",
  },
  {
    d: "7932 Billiard and Pool Establishments",
    value: "7932",
  },
  {
    d: "7933 Bowling Alleys",
    value: "7933",
  },
  {
    d: "7941 Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters",
    value: "7941",
  },
  {
    d: "7991 Tourist Attractions and Exhibits",
    value: "7991",
  },
  {
    d: "7992 Public Golf Courses",
    value: "7992",
  },
  {
    d: "7993 Video Amusement Game Supplies",
    value: "7993",
  },
  {
    d: "7994 Video Game Arcades/Establishments",
    value: "7994",
  },
  {
    d: "7995 Betting, including Lottery Tickets, Casino",
    value: "7995",
  },
  {
    d: "7996 Amusement Parks, Circuses, Carnivals, and Fortune Tellers",
    value: "7996",
  },
  {
    d: "7997 Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses",
    value: "7997",
  },
  {
    d: "7998 Aquariums, Seaquariums, Dolphinariums, and Zoos",
    value: "7998",
  },
  {
    d: "7999 Recreation Services (Not Elsewhere Classified)",
    value: "7999",
  },
  {
    d: "8011 Doctors and Physicians",
    value: "8011",
  },
  {
    d: "8021 Dentists and Orthodontists",
    value: "8021",
  },
  {
    d: "8031 Osteopaths",
    value: "8031",
  },
  {
    d: "8041 Chiropractors",
    value: "8041",
  },
  {
    d: "8042 Optometrists and Ophthalmologists",
    value: "8042",
  },
  {
    d: "8043 Opticians, Optical Goods, and Eyeglasses",
    value: "8043",
  },
  {
    d: "8049 Podiatrists and Chiropodists",
    value: "8049",
  },
  {
    d: "8050 Nursing and Personal Care",
    value: "8050",
  },
  {
    d: "8062 Hospitals",
    value: "8062",
  },
  {
    d: "8071 Medical and Dental Laboratories",
    value: "8071",
  },
  {
    d: "8099 Medical Services and Health Practitioners (Not Elsewhere Classified)",
    value: "8099",
  },
  {
    d: "8111 Legal Services and Attorneys",
    value: "8111",
  },
  {
    d: "8211 Elementary and Secondary Schools",
    value: "8211",
  },
  {
    d: "8220 Colleges, Universities, Professional Schools, and Junior Colleges",
    value: "8220",
  },
  {
    d: "8241 Correspondence Schools",
    value: "8241",
  },
  {
    d: "8244 Business and Secretarial Schools",
    value: "8244",
  },
  {
    d: "8249 Vocational and Trade Schools",
    value: "8249",
  },
  {
    d: "8299 Schools and Educational Services (Not Elsewhere Classified)",
    value: "8299",
  },
  {
    d: "8351 Child Care Services",
    value: "8351",
  },
  {
    d: "8398 Charitable and Social Service Organizations",
    value: "8398",
  },
  {
    d: "8641 Civic, Social, and Fraternal Associations",
    value: "8641",
  },
  {
    d: "8651 Political Organizations",
    value: "8651",
  },
  {
    d: "8661 Religious Organizations",
    value: "8661",
  },
  {
    d: "8675 Automobile Associations",
    value: "8675",
  },
  {
    d: "8699 Membership Organizations (Not Elsewhere Classified)",
    value: "8699",
  },
  {
    d: "8734 Testing Laboratories (Non-Medical Testing) (B2B)",
    value: "8734",
  },
  {
    d: "8911 Architectural, Engineering, and Surveying Services",
    value: "8911",
  },
  {
    d: "8931 Accounting, Auditing, and Bookkeeping Services",
    value: "8931",
  },
  {
    d: "8999 Professional Services (Not Elsewhere Classified)",
    value: "8999",
  },
  {
    d: "9211 Court Costs, Including Alimony and Child Support",
    value: "9211",
  },
  {
    d: "9222 Fines",
    value: "9222",
  },
  {
    d: "9223 Bail and Bond Payments",
    value: "9223",
  },
  {
    d: "9311 Tax Payments",
    value: "9311",
  },
  {
    d: "9399 Government Services",
    value: "9399",
  },
  {
    d: "9402 Postal Services - Government Only",
    value: "9402",
  },
  {
    d: "9405 U.S. Federal Government Agencies or Departments",
    value: "9405",
  },
  {
    d: "9406 Government-Owned Lotteries (Specific Countries)",
    value: "9406",
  },
  {
    d: "9700 Automated Referral Service",
    value: "9700",
  },
  {
    d: "9702 Emergency Services (GCAS)",
    value: "9702",
  },
  {
    d: "9950 Intra-Company Purchases (B2B)",
    value: "9950",
  },
];

export default MCC;
