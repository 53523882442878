
import { t } from 'i18next';
import { HTTP_STATUS } from '../../../../components/constants';
import { AUTHENTICATION } from '../../../../components/constants/api-paths';
import DasSnackbar from '../../../../components/das-snackbar/DasSnackbar';
import { useFetchWrapper as Api } from '../../../../utils';
export const getUserProfile = async (values: any) => {

  const loginDetails = await Api().post(AUTHENTICATION.LOGIN_API, values);
  if([HTTP_STATUS.INTERNAL_SERVER].includes(loginDetails.status)) {
    DasSnackbar.error(t(`API_STATUS_MESSAGE.${loginDetails.messageCode}`));
  }
  return loginDetails?.data;
};
