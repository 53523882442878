import i18n from '../../i18n';

export const DRAWER_TYPE = {
  DETAILS: 'DETAILS',
  REFUND: 'REFUND',
  CAPTURE: 'CAPTURE',
  RISK: 'RISK',
  PRODUCT: 'PRODUCT',
  QR_PRODUCT: 'QR_PRODUCT',
  NESTED_TABLE_FROM_PRODUCT_TAB: 'NESTED_TABLE_FROM_PRODUCT_TAB',
  NESTED_TABLE: 'NESTED_TABLE',
  PRODUCT_RISK: 'PRODUCT_RISK',
  PRODUCT_RISK_ADD: 'PRODUCT_RISK_ADD',
  PRODUCT_PBL: 'PRODUCT_PBL',
  ADD_PBL: 'ADD_PBL',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ACQUIRER_MID_DETAILS: 'ACQUIRER_MID_DETAILS',
  MERCHANT_STATEMENT_EDIT: 'MERCHANT_STATEMENT_EDIT',
  DISPUTE_DRAWER: 'DISPUTE_DRAWER',
  DISPUTE_CREATE_DRAWER: 'DISPUTE_CREATE_DRAWER',
  DISPUTE_EDIT_DRAWER: 'DISPUTE_EDIT_DRAWER',
  PARTNER_CREATE_EMAIL: 'PARTNER_CREATE_EMAIL',
  ADD_HOLIDAY: 'ADD_HOLIDAY',
  SEND_TO_ACQUIRER: 'SEND_TO_ACQUIRER',
  MISSING_INFO: 'MISSING_INFO',
  SUBMIT_EVIDENCE: 'SUBMIT_EVIDENCE',
  CHALLENGE_DISPUTE: 'CHALLENGE_DISPUTE',
  ACCEPT_DISPUTE: 'ACCEPT_DISPUTE',
  CASE_CLOSE: 'CASE_CLOSE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CREATE_SUBSCRIPTION_PLAN: 'CREATE_SUBSCRIPTION_PLAN',
  VIEW_SUBSCRIPTION_PLAN: 'VIEW_SUBSCRIPTION_PLAN',
  VIEW_SUBSCRIBER: 'VIEW_SUBSCRIBER',
  EDIT_SUBSCRIBER: 'EDIT_SUBSCRIBER',
  VIEW_SUBSCRIPTION: 'VIEW_SUBSCRIPTION',
  ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
  ADD_USER: 'ADD_USER',
  CHILDREN: 'CHILDREN',
  ONBOARDING_MISSING_INFO:"ONBOARDING_MISSING_INFO",
  ONBOARDING_UPLOAD_MISSING_INFO:"ONBOARDING_UPLOAD_MISSING_INFO",
  MERCHANT_DETAILS:"MERCHANT_DETAILS",
  ACQUIRER_DETAILS:"ACQUIRER_DETAILS",
  MDR_RATES_FORM:"MDR_RATES_FORM",
};

export const DRAWER_WIDTH = {
  DEFAULT: '0',
  DETAILS: '82%',
  REFUND: '26%',
  MERCHANT_DETAILS: '26%',
  ACQUIRER_DETAILS: '26%',
  CAPTURE: '26%',
  RISK: '62%',
  PRODUCT: '40%',
  QR_PRODUCT: '45%',
  NESTED_TABLE: '62%',
  NESTED_TABLE_FROM_PRODUCT_TAB: '62%',
  PRODUCT_RISK: '62%',
  PRODUCT_RISK_ADD: '30%',
  PRODUCT_PBL: '70%',
  ADD_PBL: '70%',
  ADD_PRODUCT: '28%',
  ACQUIRER_MID_DETAILS: '32%',
  MERCHANT_STATEMENT_EDIT: '40%',
  DISPUTE_DRAWER: '82%',
  DISPUTE_CREATE_DRAWER: '62%',
  DISPUTE_EDIT_DRAWER: '62%',
  PARTNER_CREATE_EMAIL: '35%',
  ADD_HOLIDAY: '30%',
  SEND_TO_ACQUIRER: '40%',
  MISSING_INFO: '40%',
  SUBMIT_EVIDENCE: '40%',
  CHALLENGE_DISPUTE: '40%',
  ACCEPT_DISPUTE: '40%',
  CASE_CLOSE: '40%',
  SUBSCRIPTION: '75%',
  CREATE_SUBSCRIPTION_PLAN: '30%',
  VIEW_SUBSCRIPTION_PLAN: '40%',
  VIEW_SUBSCRIBER: '40%',
  EDIT_SUBSCRIBER: '40%',
  VIEW_SUBSCRIPTION: '50%',
  ADD_SUBSCRIPTION: '50%',
  ADD_USER: '30%',
  SIXTY: '60%',
  FORTY_FIVE: '45%',
  TWENTY_FIVE: '25%',
  THIRTY_ONE: '31%',
  MDR_RATES_FORM: '30%',

};

export const DRAWER_TITLE = {
  DEFAULT: '',
  DETAILS: i18n.t('TransactionDetail.TransactionDetailHeading'),
  REFUND: i18n.t('ISSUE_A_REFUND.label'),
  CAPTURE: i18n.t('TransactionCaptureDrawerBody.ISSUE_A_CAPTURE'),
  ADD_RISK: i18n.t('Configure Rules'),
  EDIT_RISK: i18n.t('Edit Risk Rule'),
  // PRODUCT: 'PRODUCT',
  // QR_PRODUCT: 'QR_PRODUCT',
  // NESTED_TABLE: 'QR',
  // NESTED_TABLE_FROM_PRODUCT_TAB: 'QR',
  PRODUCT_RISK: i18n.t('Risk Configuration'),
  PRODUCT_RISK_ADD: i18n.t('Add Risk Configuration'),
  // PRODUCT_PBL: 'Pay By Link',
  // ADD_PBL: 'Pay By Link',
  ADD_PRODUCT: i18n.t('PayByLinkConfiguration.Cart_Details.Cart Items'),
  ACQUIRER_MID_DETAILS: i18n.t('AcquirerList.AddAcquirerDetails'),
  EDIT_ACQUIRER_MID_DETAILS: i18n.t('AcquirerList.EditAcquirerDetails'),
  MERCHANT_STATEMENT_EDIT: i18n.t('FinanceStatements.EditMerchantStatements.title'),
  DISPUTE_DRAWER: i18n.t('DisputeManagement.DisputeDetailHeading'),
  DISPUTE_CREATE_DRAWER: i18n.t('IssueChargebackDrawer.Issue_Chargeback'),
  DISPUTE_EDIT_DRAWER: i18n.t('IssueChargebackDrawer.Issue_Chargeback'),
  PARTNER_CREATE_EMAIL: i18n.t('ResellerDetails.SendReferralLinkViaEmail.Create Email'),
  CREATE_NEW_SUBSCRIPTIONP_PLAN: i18n.t(
    'Merchant_Detail.Subscription.drawer.addSubscriptionPlan',
  ),
  VIEW_SUBSCRIPTION_PLAN: i18n.t(
    'Merchant_Detail.Product_Information.view_subscription_Plan',
  ),
  EDIT_SUBSCRIPTION_PLAN: i18n.t(
    'Merchant_Detail.Product_Information.Edit_Subscription_Plan',
  ),
  VIEW_SUBSCRIBER: i18n.t(
    'Merchant_Detail.Product_Information.View_Subscriber',
  ),
  EDIT_SUBSCRIBER: i18n.t(
    'Merchant_Detail.Product_Information.Edit_Subscriber',
  ),
  VIEW_SUBSCRIPTION: i18n.t(
    'Merchant_Detail.Product_Information.View_Subscriptions',
  ),
  ADD_SUBSCRIPTION: i18n.t(
    'Merchant_Detail.Product_Information.Create_Subscription',
  ),
  EDIT_SUBSCRIPTION: i18n.t(
    'Merchant_Detail.Product_Information.Edit_Subscription',
  ),
  EDIT_CART_ITEM: i18n.t(
    'PayByLinkConfiguration.Pay By Link',
  ),
  EDIT_HOLIDAY: i18n.t('Holiday.EditButton'),
  RISK_CONFIGURATION: i18n.t('Risk Configuration'),
  PAY_BY_LINK: i18n.t('PayByLinkConfiguration.Pay By Link'),
  ADD_USER: i18n.t('Merchant_Detail.User_Management.Add_New_User.add_label'),
  EDIT_USER: i18n.t('Merchant_Detail.User_Management.Add_New_User.edit_label'),
  ONBOARDING_MISSING_INFO: i18n.t('DisputeManagement.DisputeListButtons.Missing Info'),
  ONBOARDING_UPLOAD_MISSING_INFO: i18n.t('Upload Missing Info'),
  MDR_RATES_FORM: i18n.t("MDR.Add_MDR_Rates"),
};
