import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import './i18n';
import { SnackbarProvider } from 'notistack';
import Loader from './components/loader';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
// import NoInternetConnection from "./components/no-internet/NoInternet";
let persistor = persistStore(store);
const App = lazy(
  () => import('./components/app/index' /* webpackChunkName: "App" */),
);

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        `${process.env.REACT_DAS_APP_DEV_URL}`
      ],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.render(
  <>
    {/* <NoInternetConnection> */}
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      preventDuplicate
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<Loader isLoading={true} />}>
              <App />
          </Suspense>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
    {/* </NoInternetConnection> */}
  </>,
  document.getElementById('root'),
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
