import COUNTRIES from '../config/common/countries';
import TIMEZONE from '../config/common/timezone';
import MCC from '../config/common/mcc';
import CURRENCY, { CurrencyType } from '../config/common/currency';
import {
  TRANSACTION_STATUS,
  CARD_TYPE,
  // TRANSACTION_STATUS_LABEL,
} from '../pages/transaction/components/constants/transaction';
import Pending from '../assets/transaction-icons/payment-pending.png';
import Failed from '../assets/transaction-icons/payment-failed.png';
import Capture from '../assets/transaction-icons/payment-captured.png';
import Purcahse from '../assets/transaction-icons/purchase-successful.png';
import Refund from '../assets/transaction-icons/payment-refunded.png';
import Authorisation from '../assets/transaction-icons/payment-authorized.png';
import VoidAuthorised from '../assets/transaction-icons/voided-authorization.png';
import visa from '../assets/cards/visa.png';
import mastercard from '../assets/cards/mastercard.png';
import amex from '../assets/cards/amex.png';
import jcb from '../assets/cards/jcb.png';
import invalid from '../assets/cards/invalid-card.png';
import googlepay from '../assets/cards/thumb-GPay.png';
import applepay from '../assets/cards/thumb-APay.png';
import gcash from '../assets/cards/gcash.svg';
import dinersclub from '../assets/cards/diners-club.png';
import i18n from '../i18n';
import { subsidaryLevel } from '../config/common/subsidaryLevel';
import { refreshToken } from './token-data';
import { hasAccess } from './has-access';
import { chargebackButtonStatus } from '../config/common/chargeback_button_status';
import { CHARGE_BACK_STATUS } from '../config/common/charge-back-types';
import { DateComparisionOperators } from '../pages/statements/components/holiday-list/constants/holiday';
import countries from '../config/common/countries';
const { formatInTimeZone } = require('date-fns-tz');
import valid from "card-validator";

/*eslint no-unused-vars: ["error", { "args": "none" }]*/

// var monthNames = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

// var dayOfWeekNames = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];
const getCountry = cd => {
  return COUNTRIES.find(c => c.value == cd || c.label == cd || c.name == cd);
};

const getTimeZone = cd => {
  return TIMEZONE.find(c => c.value == cd);
};

const getMCC = mcc => {
  const updatedMcc = MCC.find(mccData => mccData.value === mcc);
  return updatedMcc?.d;
};

const getCurrency = currency => {
  const updatedCurrency = CURRENCY.find(
    currData => currData.value === currency,
  );
  return updatedCurrency?.label || '';
};
export const getCookie = key => {
  if (typeof window !== 'undefined') {
    let authString = document.cookie.split(';').find(e => e.includes(key));
    let jwtValue = authString?.split('=')[1]?.trim() || null;
    return jwtValue;
  }
};

const getLanguageCurrency = language => {
  const updatedCurrency = CURRENCY.find(
    currData => currData.language === language,
  );
  return updatedCurrency?.value || '';
};

export default function getEntity(SUBSIDARY) {
  return SUBSIDARY?.map(entity => {
    return {
      label: subsidaryLevel[entity],
      value: entity,
    };
  }).filter(item => item.label);
}

const entity = JSON.parse(getCookie('Subsidiaries'))?.map(itm => {
  let entityItem = { label: subsidaryLevel[itm], value: itm };
  return entityItem;
});

const convertTime = timeStr => {
  if (timeStr) {
    let [hours, minutes] = timeStr.split(':');
    let modifier = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    if (hours === '00' && minutes === '00') {
      hours = '12';
      modifier = 'AM';
    }
    return `${hours}:${minutes} ${modifier}`;
  }
};
const newDateFormat = date => {
  return date
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    .replace(/ /g, '-');
};

function getformatDate(date, patternStr = 'dd MMMM yyyy', timezone) {
  var selectedTimeZone = localStorage.getItem('timeZone');
  if (!patternStr) {
    patternStr = 'M/d/yyyy';
  }
  // if(timezone)
  //   return formatInTimeZone(date, timezone, patternStr)
  if (localStorage.getItem('timeZone')) {
    return formatInTimeZone(date, selectedTimeZone, patternStr);
  }
  return date.toLocaleDateString(i18n.t('languagecode'), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    // year: 'numeric',
    // month: 'long',
    // day: '2-digit', // Day of the month (e.g., 01, 02, etc.)
    // hour: '2-digit', // Hour in 12-hour format (e.g., 01, 02, etc.)
    // minute: '2-digit', // Minutes (e.g., 01, 02, etc.)
    // second: '2-digit', // Seconds (e.g., 01, 02, etc.)
    hour12: true, // Use 12-hour clock (true) or 24-hour clock (false)
  });
}

function getformatLocalTime(date) {
  if (!date) return 'N/A';
  //hh:mm am/pm
  return new Date(date).toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

function format(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const getStatusIcon = status => {
  const transactionStatusIcon =
    status === TRANSACTION_STATUS.SUCCESSFUL
      ? Capture
      : status === TRANSACTION_STATUS.NOTSUCCESSFUL
      ? Failed
      : status === TRANSACTION_STATUS.PENDING
      ? Pending
      : '';
  return transactionStatusIcon;
};

const getCardType = type => {
  const transactionCardTypeIcon =
    type === CARD_TYPE.VISA
      ? visa
      : type === CARD_TYPE.MASTERCARD
      ? mastercard
      : type === CARD_TYPE.AMEX
      ? amex
      : type === CARD_TYPE.JCB
      ? jcb
      : type === CARD_TYPE.googlepay
      ? googlepay
      : type === CARD_TYPE.applepay
      ? applepay
      : type === CARD_TYPE.dinersclub
      ? dinersclub
      : type === CARD_TYPE.gcash
      ? gcash
      : invalid;
  return transactionCardTypeIcon;
};

const getAmount = (amount, currency) => {
  return amount
    ? (amount = `${
        currency === CurrencyType.JPY
          ? parseFloat(amount).toFixed(0)
          : parseFloat(amount).toFixed(2)
      }`)
    : 0;
};

const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = '';
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
const regext_pattern = new RegExp(/^[ A-Za-z0-9_]*$/);
const regextPattern = new RegExp(/^[ A-Za-z0-9]*$/);
const numRegexPattern = new RegExp(
  /^[A-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]*$/i,
);
const alphaSpaceRegexPattern = new RegExp(/^[a-zA-Z ]*$/gm);

const nameRegexPattern = new RegExp(
  /[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}ー－々〆〤゛゜ａ-ｚＡ-Ｚ０-９々〆〤〆ヵヶ〆ヶ〆a-zA-Z]/u,
);
const validatePasswordSpecialChracaterPattern = new RegExp(
  /(?=.*\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Z]).+/,
);
const phoneNumberRegexPattern = new RegExp(/^(0|[1-9][0-9]*)$/);
const emailValidationRegexPattern = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
const alphaNumericRegexWithSomeSpecialCharacters = new RegExp(
  /^[\w]+([-_'\s]{1}[a-z0-9]+)*$/i,
);
const formRuleValidation = (name, whitespace, type, pattern) => {
  const message = i18n.t(name) + i18n.t('OnBoarding.IS_REQUIRED');
  let validate = [
    {
      required: true,
      message,
    },
  ];
  if (pattern) {
    validate[0].pattern = pattern;
  }
  if (whitespace !== false) {
    validate[0].whitespace = true;
  }
  if (type) {
    validate[0].type = type;
  }
  return validate;
};

const checkFileSize = file => {
  const isLt2M = file?.size / 1024 / 1024 < 2;
  return isLt2M;
};

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// const validateEmail = (email) => {
//   return String(email)
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     );
// };

// Compare two dates

const compareDates = d2 => {
  let date1 = new Date().getTime(); // current date
  let date2 = new Date(d2).getTime(); // User entered date
  let status;
  if (date2 >= date1) {
    status = true;
  } else {
    status = false;
  }
  return status;
};
// For Authenticated Status
const getAuthenticatedStatus = () => {
  if (!!refreshToken) return true;
  return false;
};

const getTransactionTypeIconClass = (TransactionType, status) => {
  switch (TransactionType) {
    case 'PURCHASE':
      if (status === 'NOTSUCCESSFUL') {
        return Failed;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      return Purcahse;
    case 'REFUND':
      if (status === 'NOTSUCCESSFUL') {
        return Failed;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      return Refund;
    case 'AUTHORISATION':
      if (status === 'NOTSUCCESSFUL') {
        return Failed;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      return Authorisation;
    case 'CAPTURE':
      if (status === 'NOTSUCCESSFUL') {
        return Failed;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      return Capture;
    case 'VOIDAUTHORISATION':
      if (status === 'NOTSUCCESSFUL') {
        return Failed;
      }
      if (status === TRANSACTION_STATUS.PENDING) {
        return Pending;
      }
      return VoidAuthorised;
    default:
      return 'transaction-default-status';
  }
};

function convertAdjustmentAmountStringToNumber(input) {
  let cleanedInput = input.replace(/[()]/g, '').replace(/,/g, '');

  let number = parseFloat(cleanedInput);

  if (input.includes('(') && input.includes(')')) {
    number = -number;
  }

  return number;
}

function nFormatter(num, currencyCode) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ',000' },
    { value: 1e6, symbol: ',000,000' },
    { value: 1e9, symbol: ',000,000,000' },
    { value: 1e12, symbol: ',000,000,000,000' },
    // { value: 1e15, symbol: "P" },
    // { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value)
        .toFixed(currencyCode === CurrencyType.JPY ? 0 : 2)
        .replace(rx, '$1') + item.symbol
    : '0';
}
const uuidTestRegexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const validateEmail = () => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email => {
    const result = re.test(String(email).toLowerCase());
    return result === false ? i18n.t('Invalid email') : undefined;
  };
};
const productFileSizeValidator = ({ maxSize }) => {
  const accept = ['image/jpeg', 'image/png', 'image/bmp'];
  return value => {
    const maxSizeInKB = maxSize / 1024;
    if (value && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
      const currentSizeInKB = value.inputFiles[0].size / 1000;

      const formattedMaxSize = Math.floor(maxSizeInKB); // Format maxSize to two decimal places
      const formattedCurrentSize = Math.floor(currentSizeInKB); // Format currentSize to two decimal places

      return i18n
        .t('PayByLinkConfiguration.file_upload.fileSize')
        .replace('$maxSize', formattedMaxSize)
        .replace('$size', formattedCurrentSize);
    }
    if (value && accept.includes(value?.inputFiles[0]?.type) === false) {
      return i18n.t('PayByLinkConfiguration.file_upload.imageType');
    }
  };
};
const fileSizeValidator = maxSize => {
  return value => {
    if (value.inputFiles) {
      if (value && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
        return i18n
          .t(
            'File is too large, maximum allowed size is $maxSize kb. Current file has $size kb.',
          )
          .replace('$maxSize', `${maxSize / 1000}`)
          .replace('$size', `${value.inputFiles[0].size / 1000}`);
      }
    }
  };
};
const contactUsFileSizeValidator = maxSize => {
  const accept = [
    'image/jpeg',
    'image/png',
    'image/bmp',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
  ];
  return value => {
    if (value && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
      return i18n
        .t('ContactUs.SupportForm.fields.file_upload.file Size')
        .replace('$maxSize', `${maxSize / 1000000}`)
        .replace('$size', `${value.inputFiles[0].size / 1000000}`);
    }
    if (value && accept.includes(value?.inputFiles[0]?.type) === false) {
      return i18n.t('ContactUs.SupportForm.fields.file_upload.image Type');
    }
  };
};

const statusIsActive = status => {
  if (status === true) {
    return 'ACTIVE';
  } else {
    return 'INACTIVE';
  }
};
const getAmountAndCurrency = (amount, currency) => {
  return `${amount} ${currency} `;
};

const getCountryCodesList = countries =>
  countries.map(item => {
    return { label: `${item.flag}${item.label}`, value: item.label };
  });
const getCountryCode = country =>
  countries?.find(item => {
    return item.value === country;
  })?.label || 'JP';
const getOptionsFromArray = countries =>
  countries?.map(item => {
    return { label: `${item.name} ${item.flag}`, value: item.value };
  });
const getOptionsFromObject = companyType =>
  Object.entries(companyType).map(item => {
    return { label: i18n.t(item[1]), value: item[0] };
  });

const getCountryLabel = countryCode => {
  const country = COUNTRIES.find(country => country.value === countryCode);

  if (country) {
    return country.label;
  } else {
    return '+81';
  }
};

const getChargeBackButtonStatus = (
  customerFacingButtons = false,
  showButtons = false,
  chargebackBtnName,
  status = CHARGE_BACK_STATUS.Closed,
  chargebackHistoryStatus,
  // hasSubAccessLevel= false,
  // subAccessLevel
) => {
  const isAccess = customerFacingButtons
    ? showButtons
    : hasAccess('TRANSACTION_ACTION_BUTTONS');
  if (isAccess) {
    let isStatus = false;
    if (status === CHARGE_BACK_STATUS.Open) {
      isStatus =
        chargebackButtonStatus[chargebackBtnName][status][
          chargebackHistoryStatus
        ]?.buttonStatus;
    } else {
      isStatus =
        chargebackButtonStatus[chargebackBtnName][status]?.buttonStatus;
    }
    if (isStatus) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const riskFileSizeValidator = ({ maxSize }) => {
  const maxSizeInKB = maxSize / 1000;
  const accept = ['text/csv'];
  return value => {
    if (value?.inputFiles && !accept?.includes(value?.inputFiles[0]?.type)) {
      return i18n.t('RiskRule.FileType');
    }
    if (value.inputFiles) {
      if (value && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
        const currentSizeInKB = value.inputFiles[0].size / 1000;
        const formattedMaxSize = Math.floor(maxSizeInKB); // Format maxSize to two decimal places
        const formattedCurrentSize = Math.floor(currentSizeInKB); // Format currentSize to two decimal places
        return i18n
          .t(
            'File is too large, maximum allowed size is $maxSize kb. Current file has $size kb.',
          )
          .replace('$maxSize', formattedMaxSize)
          .replace('$size', formattedCurrentSize);
      }
    }
  };
};

function isDateLessThanOneYearAgo(dateToCheck) {
  // Get today's date
  const today = new Date();

  // Calculate the difference in years
  const diffInYears = today.getFullYear() - new Date(dateToCheck).getFullYear();

  // Check if the date is less than 1 year ago
  return diffInYears < 1;
}
const holidayMonthFormat = date => {
  let formatMonth = getformatDate(new Date(date), 'MM');
  let formatYear = getformatDate(new Date(date), 'yyyy');
  return `Month=${formatMonth}&Year=${formatYear}`;
};

function dateComparisionFunction(compare, dateString) {
  // Convert the date string to a Date object
  let givenDate = new Date(dateString);
  //setting time part to zero as we want to compare only the date.
  givenDate.setHours(0, 0, 0, 0);

  // Get today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Compare the dates
  if (compare === DateComparisionOperators.LessThanToday) {
    return givenDate < today;
  } else if (compare === DateComparisionOperators.LessThanOrEqualsToToday) {
    return givenDate <= today;
  } else if (compare === DateComparisionOperators.EqualsToToday) {
    return givenDate === today;
  } else if (compare === DateComparisionOperators.GreaterThanToday) {
    return givenDate > today;
  } else if (compare === DateComparisionOperators.GreaterThanOrEqualsToToday) {
    return givenDate >= today;
  }
}
// function for making filter in ascending orders.
function showInAscendingOrder(a, b) {
  if (a.headerName < b.headerName) {
    return -1;
  }
  if (a.headerName > b.headerName) {
    return 1;
  }
  return 0;
}
export const getCardScheme = (cardNumber) => {
  const cardType = valid.number(cardNumber).card?.type;
  if(cardType==='american-express') return 'AMEX';
  else return cardType;
  
};


export {
  // getAccess,
  newDateFormat,
  // getSideMenu,
  getCountry,
  getMCC,
  getCurrency,
  getTimeZone,
  getformatDate,
  convertTime,
  format,
  getStatusIcon,
  getCardType,
  getformatLocalTime,
  getAmount,
  getBase64,
  formRuleValidation,
  getLanguageCurrency,
  regextPattern,
  regext_pattern,
  numRegexPattern,
  checkFileSize,
  normFile,
  readFileAsync,
  validateEmail,
  alphaSpaceRegexPattern,
  nameRegexPattern,
  compareDates,
  getAuthenticatedStatus,
  getTransactionTypeIconClass,
  nFormatter,
  uuidTestRegexExp,
  getEntity,
  fileSizeValidator,
  productFileSizeValidator,
  statusIsActive,
  getAmountAndCurrency,
  getCountryCodesList,
  getCountryLabel,
  getOptionsFromArray,
  getOptionsFromObject,
  contactUsFileSizeValidator,
  getChargeBackButtonStatus,
  riskFileSizeValidator,
  entity,
  isDateLessThanOneYearAgo,
  holidayMonthFormat,
  dateComparisionFunction,
  showInAscendingOrder,
  validatePasswordSpecialChracaterPattern,
  phoneNumberRegexPattern,
  emailValidationRegexPattern,
  alphaNumericRegexWithSomeSpecialCharacters,
  convertAdjustmentAmountStringToNumber,
  getCountryCode,
};
