import { HTTP_STATUS } from '../components/constants';
import { setLoginDetails } from '../redux/features/auth';
import store from '../redux/store';

export async function validateTokenExpiryAndGetToken(
  authData: any,
  request: any,
): Promise<string | boolean> {

  const { idToken, accessToken, refreshToken } = authData.token;
  const tokenExp = authData && (authData?.exp - 300) * 1000;
  const email = authData && authData?.email;
  if (!refreshToken) {
    return false;
  }
  let currentTimeStamp = new Date().getTime();
  const currentExpiry = parseInt(tokenExp);
  if (
    currentExpiry < currentTimeStamp ||
    !accessToken ||
    isNaN(currentExpiry)
  ) {
    try {
      let api = request('POST');
      let newData = await api(
        `auth/refreshToken`,
        {
          username: email,
          refreshToken,
        },
      );
      if ([HTTP_STATUS.OK].includes(newData?.statusCode)) {
        store.dispatch(setLoginDetails(newData?.data));
        return newData?.data?.token?.idToken;
      } else {
        return false;
      }

    } catch (e) {
      localStorage.removeItem('persist:root');
      return false;
    }
  }
  return idToken;
}