type TimeZoneType = {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  label: string;
  utc: string[];
}[];
const TimeZone: TimeZoneType = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    label: "International Date Line West (UTC-12:00)",
    utc: ["Etc/GMT+12"],
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    label: "Coordinated Universal Time-11 (UTC-11:00)",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"],
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    label: " Hawaii (UTC-10:00)",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
    ],
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -8,
    isdst: true,
    label: "Alaska (UTC-09:00)",
    utc: [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
    ],
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    label: "Baja California (UTC-08:00)",
    utc: ["America/Santa_Isabel"],
  },
  {
    value: "Pacific Daylight Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    label: "Pacific Daylight Time (US & Canada) (UTC-07:00)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
  },
  {
    value: "Pacific Standard Time",
    abbr: "PST",
    offset: -8,
    isdst: false,
    label: "Pacific Standard Time (US & Canada) (UTC-08:00)",
    utc: [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT",
    ],
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    label: "Arizona (UTC-07:00)",
    utc: [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7",
    ],
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    label: "Chihuahua, La Paz, Mazatlan (UTC-07:00)",
    utc: ["America/Chihuahua", "America/Mazatlan"],
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    label: "Mountain Time (US & Canada) (UTC-07:00)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    label: "Central America (UTC-06:00)",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos",
    ],
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    label: "Central Time (US & Canada) (UTC-06:00)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    label: "Guadalajara, Mexico City, Monterrey (UTC-06:00)",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    label: "Saskatchewan (UTC-06:00)",
    utc: ["America/Regina", "America/Swift_Current"],
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    label: "Bogota, Lima, Quito (UTC-05:00)",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5",
    ],
  },
  {
    value: "Eastern Standard Time",
    abbr: "EST",
    offset: -5,
    isdst: false,
    label: "Eastern Time (US & Canada) (UTC-05:00)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "Eastern Daylight Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    label: "Eastern Daylight Time (US & Canada) (UTC-04:00)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -5,
    isdst: false,
    label: "Indiana (East) (UTC-05:00)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis",
    ],
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    label: "Caracas (UTC-04:30)",
    utc: ["America/Caracas"],
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PYT",
    offset: -4,
    isdst: false,
    label: "Asuncion (UTC-04:00)",
    utc: ["America/Asuncion"],
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -3,
    isdst: true,
    label: "Atlantic Time (Canada) (UTC-04:00)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda",
    ],
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    label: "Cuiaba (UTC-04:00)",
    utc: ["America/Campo_Grande", "America/Cuiaba"],
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    label: "Georgetown, La Paz, Manaus, San Juan (UTC-04:00)",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4",
    ],
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    label: "Santiago (UTC-04:00)",
    utc: ["America/Santiago", "Antarctica/Palmer"],
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -2.5,
    isdst: true,
    label: "Newfoundland (UTC-03:30)",
    utc: ["America/St_Johns"],
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    label: "Brasilia (UTC-03:00)",
    utc: ["America/Sao_Paulo"],
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    label: "Buenos Aires (UTC-03:00)",
    utc: [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza",
    ],
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    label: "Cayenne, Fortaleza (UTC-03:00)",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3",
    ],
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    label: "Greenland (UTC-03:00)",
    utc: ["America/Godthab"],
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    label: "Montevideo (UTC-03:00)",
    utc: ["America/Montevideo"],
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    label: "Salvador (UTC-03:00)",
    utc: ["America/Bahia"],
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    label: "Coordinated Universal Time-02 (UTC-02:00)",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -1,
    isdst: true,
    label: "Mid-Atlantic - Old (UTC-02:00)",
    utc: [],
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: 0,
    isdst: true,
    label: "Azores (UTC-01:00)",
    utc: ["America/Scoresbysund", "Atlantic/Azores"],
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    label: "Cape Verde Is. (UTC-01:00)",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 1,
    isdst: true,
    label: "Casablanca (UTC)",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
  },
  {
    value: "UTC",
    abbr: "UTC",
    offset: 0,
    isdst: false,
    label: "Coordinated Universal Time (UTC)",
    utc: ["America/Danmarkshavn", "Etc/GMT"],
  },
  {
    value: "GMT Standard Time",
    abbr: "GMT",
    offset: 0,
    isdst: false,
    label: "Edinburgh, London (UTC)",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "British Summer Time",
    abbr: "BST",
    offset: 1,
    isdst: true,
    label: "Edinburgh, London (UTC+01:00)",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 1,
    isdst: true,
    label: "Dublin, Lisbon (UTC)",
    utc: [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon",
    ],
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    label: "Monrovia, Reykjavik (UTC)",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
    ],
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 2,
    isdst: true,
    label: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich",
    ],
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    label: "Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane",
    ],
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 2,
    isdst: true,
    label: "Brussels, Copenhagen, Madrid, Paris (UTC+01:00)",
    utc: [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris",
    ],
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    label: "Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"],
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    label: "West Central Africa (UTC+01:00)",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1",
    ],
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    label: "Windhoek (UTC+01:00)",
    utc: ["Africa/Windhoek"],
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 3,
    isdst: true,
    label: "Athens, Bucharest (UTC+02:00)",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
    ],
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 3,
    isdst: true,
    label: "Beirut (UTC+02:00)",
    utc: ["Asia/Beirut"],
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    label: "Cairo (UTC+02:00)",
    utc: ["Africa/Cairo"],
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 3,
    isdst: true,
    label: "Damascus (UTC+02:00)",
    utc: ["Asia/Damascus"],
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 3,
    isdst: true,
    label: "E. Europe (UTC+02:00)",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    label: "Harare, Pretoria (UTC+02:00)",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2",
    ],
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 3,
    isdst: true,
    label: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)",
    utc: [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 3,
    isdst: false,
    label: "Istanbul (UTC+03:00)",
    utc: ["Europe/Istanbul"],
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 3,
    isdst: true,
    label: "Jerusalem (UTC+02:00)",
    utc: ["Asia/Jerusalem"],
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    label: "Tripoli (UTC+02:00)",
    utc: ["Africa/Tripoli"],
  },
  {
    value: "Jordan Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    label: "Amman (UTC+03:00)",
    utc: ["Asia/Amman"],
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    label: "Baghdad (UTC+03:00)",
    utc: ["Asia/Baghdad"],
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    label: "Kaliningrad (UTC+02:00)",
    utc: ["Europe/Kaliningrad"],
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    label: "Kuwait, Riyadh (UTC+03:00)",
    utc: [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh",
    ],
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    label: "Nairobi (UTC+03:00)",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte",
    ],
  },
  {
    value: "Moscow Standard Time",
    abbr: "MSK",
    offset: 3,
    isdst: false,
    label: "Moscow, St. Petersburg, Volgograd, Minsk (UTC+03:00)",
    utc: [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk",
    ],
  },
  {
    value: "Samara Time",
    abbr: "SAMT",
    offset: 4,
    isdst: false,
    label: "Samara, Ulyanovsk, Saratov (UTC+04:00)",
    utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 4.5,
    isdst: true,
    label: "Tehran (UTC+03:30)",
    utc: ["Asia/Tehran"],
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    label: "Abu Dhabi, Muscat (UTC+04:00)",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 5,
    isdst: true,
    label: "Baku (UTC+04:00)",
    utc: ["Asia/Baku"],
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    label: "Port Louis (UTC+04:00)",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
  },
  {
    value: "Georgian Standard Time",
    abbr: "GET",
    offset: 4,
    isdst: false,
    label: "Tbilisi (UTC+04:00)",
    utc: ["Asia/Tbilisi"],
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    label: "Yerevan (UTC+04:00)",
    utc: ["Asia/Yerevan"],
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    label: "Kabul (UTC+04:30)",
    utc: ["Asia/Kabul"],
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    label: "Ashgabat, Tashkent (UTC+05:00)",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives",
    ],
  },
  {
    value: "Yekaterinburg Time",
    abbr: "YEKT",
    offset: 5,
    isdst: false,
    label: "Yekaterinburg (UTC+05:00)",
    utc: ["Asia/Yekaterinburg"],
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PKT",
    offset: 5,
    isdst: false,
    label: "Islamabad, Karachi (UTC+05:00)",
    utc: ["Asia/Karachi"],
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    label: "Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)",
    utc: ["Asia/Kolkata", "Asia/Calcutta"],
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    label: "Sri Jayawardenepura (UTC+05:30)",
    utc: ["Asia/Colombo"],
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    label: "Kathmandu (UTC+05:45)",
    utc: ["Asia/Kathmandu"],
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    label: "Nur-Sultan (Astana) (UTC+06:00)",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos",
    ],
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    label: "Dhaka (UTC+06:00)",
    utc: ["Asia/Dhaka", "Asia/Thimphu"],
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    label: "Yangon (Rangoon) (UTC+06:30)",
    utc: ["Asia/Rangoon", "Indian/Cocos"],
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    label: "Bangkok, Hanoi, Jakarta (UTC+07:00)",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas",
    ],
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    label: "Novosibirsk (UTC+07:00)",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    label: "Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    label: "Krasnoyarsk (UTC+08:00)",
    utc: ["Asia/Krasnoyarsk"],
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    label: "Kuala Lumpur, Singapore (UTC+08:00)",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8",
    ],
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    label: "Perth (UTC+08:00)",
    utc: ["Antarctica/Casey", "Australia/Perth"],
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    label: "Taipei (UTC+08:00)",
    utc: ["Asia/Taipei"],
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    label: "Ulaanbaatar (UTC+08:00)",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 8,
    isdst: false,
    label: "Irkutsk (UTC+08:00)",
    utc: ["Asia/Irkutsk"],
  },
  {
    value: "Japan Standard Time",
    abbr: "JST",
    offset: 9,
    isdst: false,
    label: "Osaka, Sapporo, Tokyo (UTC+09:00)",
    utc: [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau",
    ],
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    label: "Seoul (UTC+09:00)",
    utc: ["Asia/Pyongyang", "Asia/Seoul"],
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    label: "Adelaide (UTC+09:30)",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    label: "Darwin (UTC+09:30)",
    utc: ["Australia/Darwin"],
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    label: "Brisbane (UTC+10:00)",
    utc: ["Australia/Brisbane", "Australia/Lindeman"],
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    label: "Canberra, Melbourne, Sydney (UTC+10:00)",
    utc: ["Australia/Melbourne", "Australia/Sydney"],
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    label: "Guam, Port Moresby (UTC+10:00)",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk",
    ],
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    label: "Hobart (UTC+10:00)",
    utc: ["Australia/Currie", "Australia/Hobart"],
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 9,
    isdst: false,
    label: "Yakutsk (UTC+09:00)",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    label: "Solomon Is., New Caledonia (UTC+11:00)",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape",
    ],
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    label: "Vladivostok (UTC+11:00)",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    label: "Auckland, Wellington (UTC+12:00)",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    label: "Coordinated Universal Time+12 (UTC+12:00)",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis",
    ],
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    label: "Fiji (UTC+12:00)",
    utc: ["Pacific/Fiji"],
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    label: "Magadan (UTC+12:00)",
    utc: [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk",
    ],
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    label: "Petropavlovsk-Kamchatsky - Old (UTC+12:00)",
    utc: ["Asia/Kamchatka"],
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    label: "Nuku'alofa (UTC+13:00)",
    utc: [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu",
    ],
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    label: "Samoa (UTC+13:00)",
    utc: ["Pacific/Apia"],
  },
];
export default TimeZone;
