export const ACCESS_GROUP_NAMES: any = {
  GUEST: 'GUEST',
  SALES: 'SALES',
  SALESOPS: 'SALESOPS',
  RISK: 'RISK',
  FRAUD: 'FRAUD',
  COMPLIANCE: 'COMPLIANCE',
  SUPPORT: 'SUPPORT',
  SETTLEMENT: 'SETTLEMENT',
  FINANCE: 'FINANCE',
  OPS: 'OPS',
  CUSTOMER: 'CUSTOMER',
  MERCHANT: 'MERCHANT',
  OPERATIONS: 'OPERATIONS',
  GUESTTHIRDPARTY: 'GUESTTHIRDPARTY',
  FINANCE_OPS: 'FINANCE OPS',
  ADMIN:'ADMIN',
  VIEWER:'VIEWER',
  EDITOR:'EDITOR',
  THIRDPARTY:'THIRDPARTY',
  SYSADMIN:'SYSADMIN'
};
