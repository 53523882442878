import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useFetchWrapper as Api } from "../../utils";

// createAsyncThunk
export const getRiskTable: any = createAsyncThunk(
  "risk/getRiskTable",
  async (apiPath) => {
    let data = await Api().get(apiPath);
    return data;
  }
);

// createSlice
export const riskTableSlice = createSlice({
  name: "riskTable",
  initialState: {
    riskTable: [],
    loading: false,
    count: 0,
    error: null,
  },
  reducers: {
    addNewRow: (state: any, { payload }) => {
      state.riskTable = [...state.riskTable, payload];
    },
    removeRow: (state: any, { payload }) => {
      let tableRowsAfterRemovingEditRow = state.riskTable.filter(
        (item: any) => item.ID !== payload.ID
      );
      state.riskTable = [...tableRowsAfterRemovingEditRow];
    },
    deleteRow: (state: any, { payload }) => {
      state.riskTable = state.riskTable.filter(
        (item: any) => item.ID !== payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRiskTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRiskTable.fulfilled, (state, action) => {
      state.loading = false;
      state.riskTable = action?.payload?.data;
      state.count = action?.payload?.length;
    });
    builder.addCase(getRiskTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const { addNewRow, removeRow, deleteRow } = riskTableSlice.actions;

export const riskRows = (state: any) => state?.riskTable?.riskTable;
export const loadingRows = (state: any) => state?.riskTable?.loading;
export const rowsCount = (state: any) => state?.riskTable?.count;
export default riskTableSlice.reducer;
