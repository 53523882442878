import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useFetchWrapper as Api } from '../../utils';

// createAsyncThunk
export const getProductRiskTable: any = createAsyncThunk(
  'productRisk/getProductRiskTable',
  async apiPath => {
    let data = await Api().get(apiPath);
    return data;
  },
);

// createSlice
export const productRiskTableSlice = createSlice({
  name: 'productRiskTable',
  initialState: {
    productRiskTable: [],
    loading: false,
    count: 0,
    error: null,
    timeFrameValue: 'RANGE_IN_MONTHS',
    riskRuleDetails: {},
  },
  reducers: {
    addNewRow: (state: any, { payload }) => {
      state.productRiskTable = [...state.productRiskTable, payload];
    },
    removeRow: (state: any, { payload }) => {
      let tableRowsAfterRemovingEditRow = state.productRiskTable.filter(
        (item: any) => item.ID !== payload.ID,
      );
      state.productRiskTable = [...tableRowsAfterRemovingEditRow];
    },
    deleteRow: (state: any, { payload }) => {
      state.productRiskTable = state.productRiskTable.filter(
        (item: any) => item.ID !== payload,
      );
    },
    setTimeFrameValue: (state, action) => {
      state.timeFrameValue = action.payload;
    },
    setRiskRuleDetails: (state, action) => {
      state.riskRuleDetails = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductRiskTable.pending, state => {
      state.loading = true;
    });
    builder.addCase(getProductRiskTable.fulfilled, (state, action) => {
      state.loading = false;
      state.productRiskTable = action?.payload?.data;
      state.count = action?.payload?.length;
    });
    builder.addCase(getProductRiskTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
// for editing I am removing the old row and adding the new row
export const { addNewRow, removeRow, deleteRow, setTimeFrameValue } =
  productRiskTableSlice.actions;

export const productRiskRows = (state: any) =>
  state?.productRiskTable?.productRiskTable;
export const loadingRows = (state: any) => state?.productRiskTable?.loading;
export const rowsCount = (state: any) => state?.productRiskTable?.count;
export const timeFrameData = (state: any) =>
  state?.productRiskTable?.timeFrameValue;
export const riskRuleDetailSData = (state: any) =>
  state?.productRiskTable?.riskRuleDetails;
export default productRiskTableSlice.reducer;
